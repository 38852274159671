// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import "firebase/compat/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

require("@firebase/firestore");

// New Production for Authentication
// const firebaseConfig = {
//   apiKey: "AIzaSyDAWCXJCIqKwONzc0wqt77CkwiWzRfzWpI",
//   authDomain: "jamr-production.firebaseapp.com",
//   projectId: "jamr-production",
//   storageBucket: "jamr-production.appspot.com",
//   messagingSenderId: "902721120566",
//   appId: "1:902721120566:web:92cd17e720240545f6eb97",
//   measurementId: "G-WNB60JK4CS"
// };

// Development
const firebaseConfig = {
  apiKey: "AIzaSyDilF4urYdbkPREaxAFlZzeKtNKUXwpCNE",
  authDomain: "jamr-54717.firebaseapp.com",
  databaseURL:
    "https://jamr-54717-default-rtdb.asia-southeast1.firebasedatabase.app/",
  projectId: "jamr-54717",
  storageBucket: "jamr-54717.appspot.com",
  messagingSenderId: "733776158738",
  appId: "1:733776158738:web:631c3ee6bae313c324f8cb",
  measurementId: "G-8TJZW243JF",
};

//LocalHost
// const firebaseConfig = {
//   apiKey: "AIzaSyBYBpWPa4WaviuNwn8zfYuf2Wa-zzGohtg",
//   authDomain: "jamr-app.firebaseapp.com",
//   databaseURL:
//     "https://jamr-app-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "jamr-app",
//   storageBucket: "jamr-app.appspot.com",
//   messagingSenderId: "452375856317",
//   appId: "1:452375856317:web:44af9bdd0920d8f25c63d4",
//   measurementId: "G-ZGH31KDQS9",
// };

// firebase.initializeApp(firebaseConfig);
fetch('/__/firebase/init.json').then(async response => {
  firebase.initializeApp(await response.json());
});
// const app = initializeApp(firebaseConfig);
// const auth = getAuth();
// export { auth };
// export const authentication = getAuth();
// export const db = getDatabase(app);
