import { createContext, useState } from "react";

const SortingContext = createContext();

export function SortingProvider({ children }) {
  const [sortOrderCost, setSortOrderCost] = useState("");
  const [sortOrderAZ, setSortOrderAZ] = useState("a-z");
  const [sortCity, setSortCity] = useState("Location");
  return (
    <SortingContext.Provider
      value={{
        sortOrderCost,
        setSortOrderCost,
        sortCity,
        setSortCity,
        sortOrderAZ,
        setSortOrderAZ,
      }}
    >
      {children}
    </SortingContext.Provider>
  );
}

export default SortingContext;
