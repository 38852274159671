import React, { useEffect, useState } from "react";
import "./styles.css";
import logo from "../../Images/jamrio-logo-footer.webp";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Footer = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);
  }, []);

  return (
    <div>
      {screenWidth > 500 && (
        <footer>
          <div className="footer-details">
            <img id="footer-logo" src={logo} alt="" />
            <ul>
              <li>
                <p id="footer-details-text">
                  Jamrio, on-demand marketplace app for content creators– the
                  ultimate platform for booking creative spaces and accessing a
                  range of essential audio & video services.
                </p>
              </li>
              <li>
                <div className="footer-social-icons-container">
                  <a
                    href="https://www.facebook.com/jamrioapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="footer-social-icons"
                      src="https://img.icons8.com/ios/100/000000/facebook-new.png"
                      alt="facebook"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/jamrioapp/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="footer-social-icons"
                      src="https://img.icons8.com/ios/100/000000/instagram-new--v1.png"
                      alt="instagram"
                    />
                  </a>
                  <a
                    href="https://twitter.com/jamrioapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="footer-social-icons"
                      src="https://img.icons8.com/ios/100/000000/twitter--v1.png"
                      alt="twitter"
                    />
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="footer-about">
            <p>About</p>
            <ul>
              <Link to="/about-us" className="link-style">
                <li>Our History</li>
              </Link>
              <a href="https://blog.jamrio.com/" className="link-style">
                <li>Blogs</li>
              </a>
              <Link to="/privacy-policy" className="link-style">
                <li>Privacy Policy</li>
              </Link>
              <Link to="/terms-and-condition" className="link-style">
                <li>Terms & Conditions</li>
              </Link>
            </ul>
          </div>
          <div className="footer-info">
            <p>Information</p>
            <ul>
              <a href="/sitemap.xml" target="__blank" className="link-style">
                <li>Sitemap</li>
              </a>
              <a href="/faqs" target="__blank" className="link-style">
                <li>FAQs</li>
              </a>
              <a
                href="https://agency.jamrio.com/"
                target="__blank"
                className="link-style"
              >
                <li>Agency</li>
              </a>
            </ul>
          </div>
          <div className="footer-contact">
            <p>Get In Touch</p>
            <ul>
              <li>
                <p id="footer-details-text">
                  Stay connected with me and let's know more about my service
                </p>
              </li>
              <li>
                <Link to="/contact-us" className="link-style">
                  <div id="footer-contact-btn">
                    <p>Contact Us</p>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </footer>
      )}
      {screenWidth < 500 && (
        <div id="footer-phone">
          <div className="footer-details">
            <img id="footer-logo-phone" src={logo} alt="" />

            <p id="footer-details-text">
              Jamrio, on-demand marketplace app for content creators– the
              ultimate platform for booking creative spaces and accessing a
              range of essential audio & video services.
            </p>
          </div>
          <div id="footer-accordion-container">
            <Accordion id="footer-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon id="expand-icon" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="faq-question">About</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-answer">
                  <ul id="about-list">
                    <Link to="/about-us" className="link-style">
                      <li>Our History</li>
                    </Link>
                    <a href="https://blog.jamrio.com/" className="link-style">
                      <li>Blogs</li>
                    </a>
                    <Link to="/privacy-policy" className="link-style">
                      <li>Privacy Policy</li>
                    </Link>
                    <Link to="/terms-and-condition" className="link-style">
                      <li>Terms & Conditions</li>
                    </Link>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion id="footer-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon id="expand-icon" />}
                aria-controls="panel2a-content"
                id="panel1a-header"
              >
                <Typography className="faq-question">Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-answer">
                  <ul id="about-list">
                    <a
                      href="/sitemap.xml"
                      target="__blank"
                      className="link-style"
                    >
                      <li>Sitemap</li>
                    </a>
                    <a href="/faqs" target="__blank" className="link-style">
                      <li>FAQs</li>
                    </a>
                    <a
                      href="https://agency.jamrio.com/"
                      target="__blank"
                      className="link-style"
                    >
                      <li>Agency</li>
                    </a>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="footer-contact">
            <p>Get In Touch</p>
            <ul className="footer-contact-list">
              <li>
                <p id="footer-contact-text">
                  Stay connected with me and let's know more about my service
                </p>
              </li>
              <li>
                <Link to="/contact-us" className="link-style">
                  <div id="footer-contact-btn">
                    <p>Contact Us</p>
                  </div>
                </Link>
              </li>
              <li>
                <div className="footer-social-icons-container">
                  <a
                    href="https://www.facebook.com/jamrioapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="footer-social-icons"
                      src="https://img.icons8.com/color/100/facebook-new.png"
                      alt="facebook"
                    />
                  </a>
                  <a
                    href="https://twitter.com/jamrioapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="footer-social-icons"
                      src="https://img.icons8.com/color/100/twitter--v1.png"
                      alt="twitter"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/jamrioapp/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="footer-social-icons"
                      src="https://img.icons8.com/fluency/48/instagram-new.png"
                      alt="instagram"
                    />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
