import React, { useContext, useEffect, useState } from "react";
import UserDetailsContext from "../../Context/UserDetailsContext";
import { Link } from "react-router-dom";
import "./listingContainerStyles.css";
import placeholderImg from "../../Images/noImg.webp";

const StudioContainerListing = (props) => {
  const { setIds } = useContext(UserDetailsContext);
  const id = props.id;
  return (
    <Link className="studio-link" to={`/studio-details/${id}`}>
      <div
        className="studio"
        onClick={() => {
          setIds({
            studioId: id,
          });
        }}
      >
        <div className="upper-container">
          <span className="studio-offer-text">50% off</span>
          <img
            className="studio-listing-image"
            src={props.image ? props.image : placeholderImg}
            alt={props.name}
          />
        </div>
        <div className="lower-container">
          <div>
            <p id="studio-name" className="studio-text">
              {props.name}
            </p>
            <p id="studio-address" className="studio-text">
              {props.address}
            </p>
          </div>
          <div className="studio-listing-bottom-container">
            <p id="studio-cost" className="studio-text">
              ₹{props.price}/hr
            </p>
            {/* <p id="studio-ratings" className="studio-text">
                {"⭐".repeat(props.ratings)}
              </p> */}
            <div className="studio-ratings-container">
              <img
                id="studio-ratings-star"
                src="https://img.icons8.com/ios-filled/50/FFB048/christmas-star.png"
                alt="star"
              />

              <p id="studio-ratings">4</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default StudioContainerListing;
