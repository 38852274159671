import "./styles.css";
import { useEffect, useContext, useState, useRef } from "react";
import InputEmoji from "react-input-emoji";

import io from "socket.io-client";
import { ArrowBack, Send } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import placeholderImage from "../../Images/noImg.webp";
import { useNavigate, useParams } from "react-router-dom";
const socket = io(
  `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/inbox`,
  {
    extraHeaders: {
      Authorization: window.localStorage.getItem("AuthToken"),
    },
  }
);

const InboxProfilesList = ({
  inboxes,
  setInboxId,
  setInboxNav,
  inboxId,
  navigate,
}) => (
  <div className="inbox-left-container">
    <div className="inbox-left-tabs">
      <p id="inbox-tab-name">All Messages</p>
    </div>
    <div className="inbox-profiles-container">
      {inboxes?.length > 0 ? (
        inboxes.map((inbox, index) => (
          <div
            onClick={() => {
              setInboxId(inbox.id);
              setInboxNav("messages");
              navigate(`/inbox/${inbox.id}`);
            }}
            className={
              inboxId === inbox.id
                ? "inbox-message-profile-selected"
                : "inbox-message-profile"
            }
          >
            <img
              id="inbox-profile-img"
              src={
                inbox?.inboxType === "engineerOrder"
                  ? inbox?.engineer?.image
                  : inbox?.inboxType === "studioOrder" ||
                    inbox?.inboxType === "inquiry"
                  ? inbox?.studio?.images[0]?.url
                  : placeholderImage
              }
              alt="profile icon of the person"
            />
            <div className="inbox-profile-content">
              <p id="inbox-profile-name">
                {inbox?.inboxType === "engineerOrder"
                  ? inbox?.engineer?.firstname + " " + inbox?.engineer?.lastname
                  : inbox?.studio?.name}
              </p>
              <p
                id={
                  inboxId === inbox.id
                    ? "inbox-profile-latest-text-selected"
                    : "inbox-profile-latest-text"
                }
              >
                {
                  inboxes[index]?.messages[inboxes[index]?.messages?.length - 1]
                    ?.text
                }
              </p>
            </div>
            {/* <div id="inbox-badge">
            <p id="inbox-badge-text">
              {inboxes[index]?.messages?.length}
            </p>
          </div> */}
          </div>
        ))
      ) : (
        <p>No Messages Yet</p>
      )}
    </div>
  </div>
);

const InboxMessageComponent = ({
  screenWidth,
  setInboxNav,
  sortedInboxMessages,
  divRef,
  messageText,
  setMessageText,
  sendMessage,
  inboxDetails,
  inboxImage,
}) => (
  <div className="inbox-right-container">
    {sortedInboxMessages.length > 0 && (
      <div className="chat-box-top">
        <img
          id="inbox-profile-img"
          src={inboxImage}
          alt="profile icon of the person"
        />
        <div className="inbox-profile-content">
          <p id="inbox-profile-name">
            {inboxDetails?.inboxType === "engineerOrder"
              ? inboxDetails?.engineer?.firstname +
                " " +
                inboxDetails?.engineer?.lastname
              : inboxDetails?.studio?.name}
          </p>
          <p id="inbox-profile-latest-text">
            {inboxDetails?.studio
              ? "Studio Owner"
              : inboxDetails?.engineer
              ? "Mixing & Mastering Engineer"
              : null}
          </p>
        </div>
        {screenWidth < 800 && (
          <ArrowBack
            fontSize="large"
            className="chat-back-arrow"
            onClick={() => {
              setInboxNav("list");
            }}
          />
        )}
      </div>
    )}
    <div className="chat-box-middle">
      {sortedInboxMessages.length > 0 &&
        sortedInboxMessages.map((message) => (
          <div
            id={
              message.sender === "user"
                ? "chat-message-sent"
                : "chat-message-received"
            }
          >
            <p id="chat-message-text">{message.text}</p>
          </div>
        ))}
      <div ref={divRef} />
    </div>
    {sortedInboxMessages.length > 0 && (
      <div className="chat-box-bottom">
        <input
          placeholder="Type your message..."
          id="chat-input"
          value={messageText}
          onChange={(text) => setMessageText(text.target.value)}
        />
        <button id="message-btn" onClick={sendMessage}>
          <Send />{" "}
        </button>
      </div>
    )}
  </div>
);

const UserInbox = () => {
  const [text, setText] = useState("");
  const [inboxes, setInboxes] = useState([]);
  const [inboxId, setInboxId] = useState("");
  const [inboxDetails, setInboxDetails] = useState();
  const [inboxListDetails, setInboxListDetails] = useState();
  const [inboxMessages, setInboxMessages] = useState([]);
  const [sortedInboxMessages, setSortedInboxMessages] = useState([]);
  const [messagereceived, setMessagereceived] = useState("");
  const [lastPong, setLastPong] = useState(null);
  const [messageText, setMessageText] = useState("");
  const divRef = useRef(null);
  const [inboxNav, setInboxNav] = useState("list");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { id } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    GetInboxes();
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);
  }, []);

  useEffect(() => {
    GetParticularInboxes(id);
    divRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
    console.log("id", id);
  }, [id, inboxId]);

  useEffect(() => {
    if (id !== null) {
      setInboxId(id);
    }
  }, [inboxes, id]);

  useEffect(() => {
    if (messagereceived !== "") {
      setInboxMessages([...inboxMessages, messagereceived]);
    }
    window.scrollTo(0, 0);
    divRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [messagereceived]);

  useEffect(() => {
    setSortedInboxMessages(
      inboxMessages.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
    );
    console.log(
      "Sorted messages by date >>>>",
      inboxMessages.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
    );
  }, [inboxMessages]);

  useEffect(() => {
    console.log("inbox nav", inboxNav);
  }, [inboxNav]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected");
    });

    socket.on("error", (err) => {
      // setIsConnected(true);
      console.log("error", err);
    });

    socket.on("send-message", (data) => {
      // setIsConnected(true);
      console.log("send message", data);
      setMessageText("");
      GetParticularInboxes(data.InboxId);
      window.scrollTo(0, 0);
    });

    socket.on("deliver-message", () => {
      console.log("deliver");
    });

    socket.on("read-message", () => {
      console.log("read");
    });

    socket.on("receive", (data) => {
      console.log("receive", data);
      setMessagereceived(data);
      window.scrollTo(0, 0);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("pong");
    };
  }, []);

  const sendMessage = () => {
    if (messageText !== "") {
      socket.emit("send-message", {
        inbox: {
          inboxId: inboxDetails?.id,
          inboxType: inboxDetails?.inboxType,
          orderId: inboxDetails?.orderId,
          studioId: inboxDetails?.studioId,
          engineerId: inboxDetails?.engineerId,
          userId: inboxDetails?.userId,
        },
        message: {
          messageType: "text",
          text: messageText,
          sender: "user",
          receiver: "studio",
        },
      });
    } else {
      toast.error("Message cannot be empty");
    }
  };

  const GetInboxes = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/inbox/user/${window.localStorage.getItem("userId")}`,
      // "http://localhost:3000/user/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Inboxes ----->", data);
          }
          setInboxListDetails(data);
          setInboxes(data.inbox);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetParticularInboxes = async (inboxId) => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/inbox/${inboxId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Particular Inbox ----->", data);
          }
          setInboxDetails(data);
          setInboxMessages(data.messages);
          setInboxNav("messages");
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="inbox-main-container">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {screenWidth > 800 ? (
        <div className="inbox-items-container">
          <InboxProfilesList
            inboxes={inboxes}
            setInboxId={setInboxId}
            setInboxNav={setInboxNav}
            inboxId={inboxId}
            navigate={navigate}
          />
          <InboxMessageComponent
            inboxMessages={inboxMessages.messages}
            screenWidth={screenWidth}
            setInboxNav={setInboxNav}
            sortedInboxMessages={sortedInboxMessages}
            divRef={divRef}
            messageText={messageText}
            setMessageText={setMessageText}
            sendMessage={sendMessage}
            inboxDetails={inboxDetails}
            inboxImage={
              inboxDetails?.inboxType === "engineerOrder"
                ? inboxDetails?.engineer?.image
                : inboxDetails?.inboxType === "studioOrder" ||
                  inboxDetails?.inboxType === "inquiry"
                ? inboxDetails?.studio?.images[0]?.url
                : placeholderImage
            }
          />
        </div>
      ) : (
        <div className="inbox-items-container">
          {inboxNav === "list" ? (
            <InboxProfilesList
              inboxes={inboxes}
              setInboxId={setInboxId}
              setInboxNav={setInboxNav}
              inboxId={inboxId}
              navigate={navigate}
            />
          ) : inboxNav === "messages" ? (
            <InboxMessageComponent
              inboxMessages={inboxMessages.messages}
              screenWidth={screenWidth}
              setInboxNav={setInboxNav}
              sortedInboxMessages={sortedInboxMessages}
              divRef={divRef}
              messageText={messageText}
              setMessageText={setMessageText}
              sendMessage={sendMessage}
              inboxDetails={inboxDetails}
              inboxImage={
                inboxDetails?.inboxType === "engineerOrder"
                  ? inboxDetails?.engineer?.image
                  : inboxDetails?.inboxType === "studioOrder" ||
                    inboxDetails?.inboxType === "inquiry"
                  ? inboxDetails?.studio?.images[0]?.url
                  : placeholderImage
              }
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default UserInbox;
