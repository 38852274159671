import React, { useContext, useEffect, useState } from "react";
import "./styles.css";
import moment from "moment";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate, useParams } from "react-router-dom";
import {
  notifyPaymentSuccess,
  notifyPaymentErr,
  notifyOrderProcessing,
  notifyReferralSuccess,
} from "../../components/ToastFunctions";
import { ToastContainer } from "react-toastify";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import placeholderImg from "../../Images/noImg.webp";
import { Add } from "@mui/icons-material";
import { orange } from "@mui/material/colors";

function PaymentMixingMastering() {
  const [storeDetails, setStoreDetails] = useState();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [engineerCoupon, setEngineerCoupon] = useState([]);
  const [couponApplied, setCouponApplied] = useState("");
  const [currentTime, setCurrentTime] = useState(
    moment().format("HH:mm").substring(0, 2)
  );
  const { name, cost, id, serviceId, stems, stems_range, couponCode } =
    useParams();
  const [finalPrice, setFinalPrice] = useState(cost * 0.18 + cost);
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [engineerDetails, setEngineerDetails] = useState();
  const [couponClicked, setCouponClicked] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState();
  const [couponCost, setCouponCost] = useState(0);
  const [isFinalAmount, setIsFinalAmount] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [walletUsage, setWalletUsage] = useState(0);
  const [walletPercentage, setWalletPercentage] = useState();
  const [walletSelected, setWalletSelected] = useState(false);

  let navigate = useNavigate();
  const TextBox = styled(TextField)(() => ({
    "& fieldset": {
      boxShadow: "0 0 10px rgba(0,0,0,0.2)",
      borderRadius: "50px",
      border: "none",
      width: "100%",
    },
  }));

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
  }, []);

  useEffect(() => {
    const url = "/dashboard?orderid=sdfdsf&paymentStatus=success&name=sachin";

    console.log("url", url);
    console.log("url 1", url.split("?")[1]);
    console.log("url 2", url.split("?")[1].split("&"));
    console.log("url 3", url.split("?")[1].split("&")[0].split("=")[1]);
    console.log("url 4", url.split("?")[1].split("&")[1].split("=")[1]);
    console.log("url 5", url.split("?")[1].split("&")[2].split("=")[1]);

    console.log("window location", window.location.pathname);
  }, []);

  //Get current time and date
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("current time", currentTime);
    }
    if (process.env.NODE_ENV === "development") {
      console.log("current date", currentDate);
    }
  }, [currentDate, currentTime]);

  const getLSItems = window.localStorage.getItem("details");

  useEffect(() => {
    if (
      window.localStorage.getItem("details") !== null ||
      window.localStorage.getItem("details") !== undefined ||
      window.localStorage.getItem("details") !== ""
    ) {
      setStoreDetails(JSON.parse(window.localStorage.getItem("details")));
    }
  }, [getLSItems]);

  useEffect(() => {
    document.title = "Jamrio | Payment";
    fetchEngineerDetails();
    fetchEngineerCoupon();
    RefreshToken();
    getWalletBalance();
    getWalletUsage();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // let grossAmount = 0;
    // grossAmount = parseFloat(cost);
    // setFinalPrice(grossAmount * 0.18 + grossAmount);
    let final = parseFloat(cost * 0.18) + parseFloat(cost);
    if (couponClicked) {
      if (coupon?.isFinalAmount) {
        final = final - parseFloat(coupon?.amount);
      } else if (!coupon?.isFinalAmount) {
        final = final - (final * parseFloat(coupon?.amount)) / 100;
      }
    }
    if (process.env.NODE_ENV === "development") {
      console.log("Final price: ", final);
      console.log("cost: ", parseFloat(cost * 0.18) + parseFloat(cost));
    }
    setFinalPrice(final);
  }, [cost, coupon, couponClicked]);

  useEffect(() => {
    if (couponCode !== "none") {
      setCouponClicked(true);
      setCouponApplied(couponCode);
    }
  }, []);

  useEffect(() => {
    if (couponClicked) {
      setCouponApplied(coupon?.code);
    } else {
      setCouponApplied("");
    }
  }, [couponClicked]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("Coupon applied: ", couponApplied);
    }
  }, [couponApplied]);

  useEffect(() => {
    if (engineerCoupon) {
      engineerCoupon.filter((coupon) => {
        if (coupon.couponId !== "test1") {
          return setCoupon(coupon.mixmasdiscountcoupon);
        }
      });
    }
    console.log("all engineer coupons", engineerCoupon);
  }, [engineerCoupon]);

  const getWalletBalance = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/wallet`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/studio/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          setWalletBalance(data);
          if (process.env.NODE_ENV === "development") {
            console.log("wallet data ----->", data);
          }
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  const getWalletUsage = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/wallet/usages`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/studio/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          setWalletUsage(data);
          if (process.env.NODE_ENV === "development") {
            console.log("wallet usage ----->", data);
          }
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  const RefreshToken = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/auth/refresh`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          alert("Please login to continue");
          window.localStorage.removeItem("AuthToken");
          navigate("/login/phone");
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data.token) {
          if (process.env.NODE_ENV === "development") {
            console.log("Refresh token ------------------- ----->", data);
          }
          window.localStorage.setItem("AuthToken", data.token.token);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchEngineerDetails = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/${id}/studioengineer`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("engineerData ----->", data);
          }
          setEngineerDetails(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  // PAYMENT

  const PaymentInitiate = async () => {
    //PRODUCTION
    if (process.env.NODE_ENV === "development") {
      console.log("Net amount", window.localStorage.getItem("netAmount"));
    }
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/payment/initiate`,
      // `https://backend.jamr.online/payment/gateway/initiate`,
      {
        //TESTING
        // await fetch(`http://localhost:3000/studio/details/?type=L`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: JSON.stringify({
          amount: window.localStorage.getItem("netAmount"),
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("payment api called ----->", data);
          }
          // alert("Transaction Successful");
          // navigate("/");
          // RazorPayPayment(data);
          // receiptId.push(data.receipt);
          window.localStorage.setItem("orderId", data.gatewayorderid);
          window.localStorage.setItem("payment-id", data.id);
          RazorPayPayment();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed in payment initiate", data);
          }
          setPaymentLoading(false);
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  // RAZORPAY PAYMENT

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
        setPaymentLoading(false);
      };

      document.body.appendChild(script);
    });
  };

  const RazorPayPayment = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Something went wrong");
      return;
    }

    const options = {
      key: "rzp_test_JQQLKxhPmex7o8", // Enter the Key ID generated from the Dashboard
      amount: window.localStorage.getItem("netAmount"), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "JAMR",
      description: "Test Transaction",
      image:
        "https://i.ibb.co/TP8VqHk/Whats-App-Image-2022-02-01-at-21-17-1.png",
      order_id: window.localStorage.getItem("orderId"), //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        setPaymentLoading(false);
        notifyOrderProcessing();
        if (process.env.NODE_ENV === "development") {
          console.log(response);
        }
        PaymentSuccess();
      },
      theme: {
        color: "#FF782C",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      setPaymentLoading(false);
      PaymentFailure();
      alert(response.error.code);
      alert(response.error.description);
    });
    rzp1.open();
  };

  //----------> New APIs <-----------

  const onContinuePayment = () => {
    if (
      window.localStorage.getItem("userId") !== undefined &&
      window.localStorage.getItem("userId") !== null &&
      window.localStorage.getItem("userId") !== ""
    ) {
      if (process.env.NODE_ENV === "development") {
        console.log("User exists");
      }

      NewOrder();
    } else {
      notifyPaymentErr("Please Login Or Register to proceed booking");
      setPaymentLoading(false);
    }
  };

  const NewOrder = async () => {
    console.log("coupon applied", couponApplied);
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/order/new`,
      {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: JSON.stringify({
          userid: window.localStorage.getItem("userId"),
          bookingdate: moment().format("YYYY-MM-DD"),
          priceperhour: 0,
          engineer_id: id,
          isjamrpackage: false,
          totalhours: 1,
          service_id: serviceId,
          steamId: stems === "nostems" ? "" : stems,
          couponCode: couponApplied
            ? couponApplied
            : couponCode !== "none"
            ? couponCode
            : "",
          useWallet: walletSelected,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.netamount) {
          if (process.env.NODE_ENV === "development") {
            console.log("Order history ----->", data);
          }
          // alert("Transaction Successful");
          // setTransactionId(data.data.transactionId);
          window.localStorage.setItem("netAmount", data.netamount);
          window.localStorage.setItem("order-id", data.id);
          // navigate("/dashboard");
          // PaymentInitiate();
          // window.location.href = `http://localhost:3000/payment/ccavenue/engineer/${data.id}`;
          // window.location.href = `https://api.jamrio.com/payment/ccavenue/engineer/${data.id}`;
          // console.log("Order Id ----->", data.id);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed in newOrder", data);
          }
          // alert("Transaction Failed");
          notifyPaymentErr(data);
          setPaymentLoading(false);
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
        alert(error);
        setPaymentLoading(false);
      });
  };

  // Payment Success
  const PaymentSuccess = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/order/${window.localStorage.getItem(
        "order-id"
      )}/status/success`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: JSON.stringify({
          paymentid: window.localStorage.getItem("payment-id"),
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          setPaymentLoading(false);
          if (process.env.NODE_ENV === "development") {
            console.log("Order Details Success ----->", data);
          }
          alert("Payment Successful!");
          notifyPaymentSuccess();
          navigate("/dashboard");
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Order Failed", data.message);
          }
          // alert(data.message);
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
        setPaymentLoading(false);
      });
  };

  const PaymentFailure = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/mixing-mastering/order/${window.localStorage.getItem(
        "order-id"
      )}/status/failure`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: JSON.stringify({
          // orderid: window.localStorage.getItem("order-id"),
          paymentid: window.localStorage.getItem("payment-id"),
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed order in payment failure", data.message);
          }
          alert(data.message);
        } else {
          setPaymentLoading(false);
          if (process.env.NODE_ENV === "development") {
            console.log("Order Details Failure ----->", data);
          }
          notifyPaymentErr("Payment Failed, Please try again later");
          // navigate("/dashboard");
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
        setPaymentLoading(false);
      });
  };

  const fetchEngineerCoupon = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/${id}/coupons`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("engineer Coupons ----->", data);
          }
          setEngineerCoupon(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  // const handleReferralSubmit = async () => {
  //   //PRODUCTION
  //   await fetch(
  //     `${process.env.REACT_APP_PROTOCOL}://${
  //       process.env.REACT_APP_DOMAIN
  //     }/user/${window.localStorage.getItem(
  //       "userId"
  //     )}/referral/verify?code=${referralCode}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     }
  //   )
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data.result === false) {
  //         if (process.env.NODE_ENV === "development") {
  //           console.log("Failed", data);
  //         }
  //         notifyPaymentErr("Invalid Referral code");
  //       } else {
  //         if (process.env.NODE_ENV === "development") {
  //           console.log("Referral Success ----->", data);
  //         }
  //         notifyReferralSuccess();
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // useEffect(() => {
  //   //Add 18% to total price
  //   setFinalPrice(
  //     parseFloat(storeDetails?.totalPrice) +
  //       parseFloat(storeDetails?.totalPrice) * 0.18
  //   );
  // }, [storeDetails]);

  // get

  const Coupon = () => (
    <button
      onClick={() => {
        setCouponClicked(!couponClicked);
        setSelectedCoupon(coupon?.code);
        setCouponCost(coupon?.amount);
        setIsFinalAmount(coupon?.isFinalAmount);
      }}
      id={`engineer-coupon-payment${
        couponClicked ? "-clicked" : walletSelected ? "-disabled" : ""
      }`}
      disabled={walletSelected}
    >
      <div className="mm-details-coupons-left-container">
        <img
          src="https://img.icons8.com/office/100/null/discount.png"
          alt="offer"
          id="mm-offer-icon"
        />
      </div>
      <div className="mm-details-coupons-right-container">
        <p id="mm-coupon-text">{coupon?.description}</p>
        <h1 id="mm-coupon-code">Use Code: {coupon?.code}</h1>
      </div>
    </button>
  );

  return (
    <div className="payment-engineer">
      <Helmet>
        <title>Jamrio | Payment</title>
        <meta
          name="description"
          content="Best place for booking and finding music studios, jampads,audio engineers, shoot locations and much more, Read blogs, Read Artist Testimonies and much more!"
        />
        <meta
          name="keywords"
          content="Music, Music Studios, Jampads, Audio Engineers, Book Studio, Shoot Locations"
        />
      </Helmet>
      <div className="payment-engineerDetails">
        <div className="paymentMM-engineerDetails-left-container">
          <div className="paymentMM-engineer-profile-details-container">
            <div className="paymentMM-engineer-profile-top-left-container">
              <img
                src={placeholderImg}
                alt="engineer profile"
                id="paymentMM-engineer-profile-img"
              />
            </div>
            <div className="paymentMM-engineer-profile-top-right-container">
              <div className="paymentMM-engineer-profile-details-name-rating-container">
                <h1 id="paymentMM-engineer-profile-name">
                  {engineerDetails?.firstname} {engineerDetails?.lastname}
                </h1>
                <p id="paymentMM-engineer-profile-username">
                  @{engineerDetails?.username}
                </p>
              </div>
            </div>
          </div>
          <div className="paymentMM-engineer-credentials-box">
            <div className="payment-credentials-container">
              <span className="payment-credentials-left">Date</span>
              <span className="payment-credentials-right">
                {moment().format("DD/MM/yyyy")}
              </span>
            </div>
            <div className="payment-credentials-container">
              <span className="payment-credentials-left">Service</span>
              <span className="payment-credentials-right">{name}</span>
            </div>
            {stems_range !== 0 && stems_range !== "0" && (
              <div className="payment-credentials-container">
                <span className="payment-credentials-left">Stems</span>
                <span className="payment-credentials-right">{stems_range}</span>
              </div>
            )}
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="paymentMM-engineerDetails-right-container">
          {coupon?.amount && (
            <div className="payment-engineerDetails-info-container">
              <Accordion id="accordion-mm" disableGutters>
                <AccordionSummary
                  expandIcon={<Add />}
                  aria-controls="panel1a-content"
                  id="accordion-summary"
                >
                  <Typography className="accordion-title">
                    Apply Coupon or Jamrio Cash{" "}
                    <img
                      src="https://img.icons8.com/sf-regular/100/FA5252/discount.png"
                      alt="offer"
                      id="offer-icon"
                    />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div id="accordion-details">
                    {coupon?.amount && <Coupon />}
                    <div className="wallet-details-container">
                      <Checkbox
                        checked={walletSelected}
                        className="checkbox-accordion"
                        sx={{
                          color: orange[600],
                          "&.Mui-checked": {
                            color: orange[600],
                          },
                          "& .MuiSvgIcon-root": { fontSize: 40 },
                        }}
                        disabled={walletBalance?.balance === 0 || couponClicked}
                        onChange={(e) => {
                          setWalletSelected(e.target.checked);
                        }}
                      />
                      <p>Use Wallet Balance ₹{walletBalance?.balance}</p>
                    </div>
                    <div>
                      <p className="wallet-text">
                        {walletUsage?.wallet_usages_text + ". "}
                        From the total amount{" "}
                        {walletUsage?.wallet_usages_precentage}% will be paid
                        from wallet.
                      </p>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          <div className="payment-engineerDetails-info-container">
            {/* <p id="paymentMM-title">{name}</p>
            <div className="engineer-payment-info-container">
              <p>Date</p>
              <p>{moment().format("MMMM Do YYYY")}</p>
            </div> */}
            {/* {stems_range !== "0" && (
              <div className="engineer-payment-info-container">
                <p>Stems</p>
                <p>{stems_range} Stems Mixing</p>
              </div>
            )} */}

            <div className="payment-breakdown-container">
              <p className="subtitle">Order Summary</p>
              <div className="engineer-payment-info-container">
                <p className="tax">Booking Price </p>
                <p id="tax-text">₹{parseFloat(cost)}</p>
              </div>
              <div className="engineer-payment-info-container">
                <p className="tax">Taxes </p>
                <p id="tax-text">₹{(parseFloat(cost) * 0.18).toFixed(2)}</p>
              </div>
              {couponClicked && (
                <div className="engineer-payment-info-container">
                  <p className="tax">Coupon Applied </p>
                  <p id="deducted-coupon-amount">
                    -{}
                    {coupon.isFinalAmount
                      ? `₹${coupon?.amount}`
                      : `₹${(
                          ((parseFloat(cost * 0.18) + parseFloat(cost)) *
                            coupon?.amount) /
                          100
                        ).toFixed(2)}`}
                  </p>
                </div>
              )}
              {walletSelected && (
                <div className="engineer-payment-info-container">
                  <p className="tax">Wallet amount used </p>
                  <p id="deducted-coupon-amount" className="red">
                    -₹
                    {/* {(
                        finalPrice.toFixed(2) *
                        parseFloat(walletUsage?.wallet_usages_precentage)
                      ).toFixed(2)} */}
                    {walletBalance?.balance >
                    (
                      finalPrice.toFixed(2) *
                      parseFloat(walletUsage?.wallet_usages_precentage)
                    ).toFixed(2)
                      ? (
                          finalPrice.toFixed(2) *
                          parseFloat(walletUsage?.wallet_usages_precentage)
                        ).toFixed(2)
                      : walletBalance?.balance}
                  </p>
                </div>
              )}
              {/* <Divider></Divider> */}
              <div className="engineer-payment-info-container">
                <p className="cost" id="cost-total">
                  Total:{" "}
                </p>
                <p id="cost-text" className="cost">
                  ₹{finalPrice}
                </p>
              </div>
            </div>
          </div>

          <div
            className="payment-engineer-btn"
            onClick={() => {
              onContinuePayment();
              setPaymentLoading(true);
              //stop payment loading
              setTimeout(() => {
                setPaymentLoading(false);
              }, 5000);
            }}
          >
            {paymentLoading ? (
              <div className="loader-payment">
                <ScaleLoader
                  color={"#fff"}
                  loading={paymentLoading}
                  height={50}
                  width={10}
                  radius={50}
                  margin={5}
                />
              </div>
            ) : (
              <p>Continue to payment</p>
            )}
          </div>
          {/* <div
            className="payment-engineer-btn"
            onClick={() => {
              onContinuePayment();
              setPaymentLoading(true);
              //stop payment loading
              setTimeout(() => {
                setPaymentLoading(false);
              }, 5000);
            }}
          >
            {paymentLoading ? (
              <div className="loader-payment">
                <ScaleLoader
                  color={"#fff"}
                  loading={paymentLoading}
                  height={50}
                  width={10}
                  radius={50}
                  margin={5}
                />
              </div>
            ) : (
              <p>Continue to payment</p>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default PaymentMixingMastering;
