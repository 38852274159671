import { lazy, Suspense, useEffect } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Navbar from "./components/Navbar/Navbar";
import Navbar from "./components/NavbarJamrio/NavbarJamrio";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UserDetailsProvider } from "./Context/UserDetailsContext";
import { BookingDetailsProvider } from "./Context/BookingDetailsContext";
import { NavigationProvider } from "./Context/NavigationContext";
import { SortingProvider } from "./Context/SortingContext";
// import { WithFilterBar } from "./components/FilterBarStatus/WithFilterBar";
// import { WithoutFilterBar } from "./components/FilterBarStatus/WithoutFilterBar";
import { ScaleLoader } from "react-spinners";
import PaymentMixingMastering from "./Pages/PaymentMixingMastering/PaymentMixingMastering";
import OrderHistoryMM from "./Pages/OrderHistoryMM/OrderHistoryMM";
import Footer from "./components/Footer/Footer";
import { Helmet } from "react-helmet";
import StudioDetailsNew from "./Pages/StudioDetailsNew/StudioDetailsNew";
import UserInbox from "./components/UserInbox/UserInbox";
import VerifyEmail from "./Pages/VerifyEmail/VerifyEmail";
// import FAQPage from "./Pages/FAQPage/FAQPage";
// import BookingSuccess from "./Pages/BookingSuccess/BookingSuccess";
const Home = lazy(() => import("./Pages/Home/Home"));
const StudioListing = lazy(() => import("./Pages/StudioListing/StudioListing"));
const JampadListing = lazy(() => import("./Pages/JampadListing/JampadListing"));
const LocationListing = lazy(() =>
  import("./Pages/LocationListing/LocationListing")
);
const StudioDetails = lazy(() => import("./Pages/StudioDetails/StudioDetails"));
const LocationDetails = lazy(() =>
  import("./Pages/LocationDetails/LocationDetails")
);
const LocationImage = lazy(() => import("./Pages/LocationImage/LocationImage"));
const Payment = lazy(() => import("./Pages/Payment/Payment"));
const ShootingPayment = lazy(() =>
  import("./Pages/ShootingPayment/ShootingPayment")
);
const UserDashboard = lazy(() => import("./Pages/UserDashboard/UserDashboard"));
const OrderHistory = lazy(() => import("./Pages/OrderHistory/OrderHistory"));
const Login = lazy(() => import("./Pages/Login/Login"));
const VerifyPhone = lazy(() => import("./Pages/VerifyPhone/VerifyPhone"));
const VerfyEmail = lazy(() => import("./Pages/VerifyEmail/VerifyEmail"));
const ComingSoon = lazy(() => import("./Pages/ComingSoon/ComingSoon"));
const Referrals = lazy(() => import("./Pages/Referrals/Referrals"));
const Wallet = lazy(() => import("./Pages/Wallet/Wallet"));
const EngineersListing = lazy(() =>
  import("./Pages/EngineersListing/EngineersListing")
);
const EngineerProfile = lazy(() =>
  import("./components/EngineerProfile/EngineerProfile")
);
const AboutUS = lazy(() => import("./Pages/AboutUS/AboutUS"));
const TermsCondition = lazy(() =>
  import("./Pages/Terms&Condition/Terms&Condition")
);
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy/PrivacyPolicy"));
const ContactUS = lazy(() => import("./Pages/ContactUS/ContactUS"));
const SubscriptionForm = lazy(() =>
  import("./Pages/SubscriptionForm/SubscriptionForm")
);
const ForgotPassword = lazy(() =>
  import("./Pages/ForgotPassword/ForgotPassword")
);
const BookingSuccess = lazy(() =>
  import("./Pages/BookingSuccess/BookingSuccess")
);

const EngineerPaymentSuccess = lazy(() =>
  import("./Pages/PaymentSuccessPages/EngineerPaymentSuccess")
);

const ErrorPage = lazy(() => import("./Pages/ErrorPage/ErrorPage"));
const FAQPage = lazy(() => import("./Pages/FAQPage/FAQPage"));

const CreatePayment = lazy(() => import("./Pages/CreatePayment/CreatePaymet"));
const JamrioSocial = lazy(() => import("./Pages/JamrioSocial/JamrioSocial"));

const UploadPost = lazy(() => import("./Pages/JamrioSocial/UploadPost"));

const ShootLocations = lazy(() =>
  import("./Pages/LocationDetails/LocationDetails")
);

const color = "#FF782C";

const Loader = () => (
  <div className="loader">
    <ScaleLoader
      color={color}
      loading={true}
      height={100}
      width={20}
      radius={100}
      margin={10}
    />
  </div>
);

function App() {
  let previewDetails = window.localStorage.getItem("previewDetails");
  useEffect(() => {
    setTimeout(() => {
      window.localStorage.removeItem("previewDetails");
    }, 300000);
  }, [previewDetails]);

  return (
    <div className="app">
      <UserDetailsProvider>
        <BookingDetailsProvider>
          <SortingProvider>
            <NavigationProvider>
              <BrowserRouter>
                <Helmet>
                  <title>Jamrio</title>
                  <meta
                    name="description"
                    content="Best place for booking and finding music studios, jampads,audio engineers, shoot locations and much more!"
                  />
                  <meta
                    name="keywords"
                    content="Music, Music Studios, Jampads, Audio Engineers, Book Studio, Shoot Locations"
                  />
                </Helmet>
                <Navbar />
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Home />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="about-us"
                    element={
                      <Suspense fallback={<Loader />}>
                        <AboutUS />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="terms-and-condition"
                    element={
                      <Suspense fallback={<Loader />}>
                        <TermsCondition />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="privacy-policy"
                    element={
                      <Suspense fallback={<Loader />}>
                        <PrivacyPolicy />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="contact-us"
                    element={
                      <Suspense fallback={<Loader />}>
                        <ContactUS />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="forgot-password/:source"
                    element={
                      <Suspense fallback={<Loader />}>
                        <ForgotPassword />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="studio-listing"
                    element={
                      <Suspense fallback={<Loader />}>
                        <StudioListing />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="jampad-listing"
                    element={
                      <Suspense fallback={<Loader />}>
                        <JampadListing />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="engineers-listing"
                    element={
                      <Suspense fallback={<Loader />}>
                        <EngineersListing />
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="location-listing"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LocationListing />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="engineer-details/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <EngineerProfile />
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="shoot-location/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <ShootLocations />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="coming-soon"
                    element={
                      <Suspense fallback={<Loader />}>
                        <ComingSoon />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="studio-details/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <StudioDetailsNew />
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="location-details"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LocationDetails />
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="shooting-payment"
                    element={
                      <Suspense fallback={<Loader />}>
                        <ShootingPayment />
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="location-images"
                    element={
                      <Suspense fallback={<Loader />}>
                        <LocationImage />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="payment/:studioId"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Payment />
                      </Suspense>
                    }
                  />

                  <Route
                    // exact path="payment/:id/:order_id/booking-success"
                    exact
                    path="/booking-status"
                    element={
                      <Suspense fallback={<Loader />}>
                        <BookingSuccess />
                      </Suspense>
                    }
                  />
                  <Route
                    // exact path="payment/:id/:order_id/booking-success"
                    exact
                    path="/engineer-booking-status"
                    element={
                      <Suspense fallback={<Loader />}>
                        <EngineerPaymentSuccess />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="engineer-payment/:id/:name/:serviceId/:cost/:stems/:stems_range/:coupon=:couponCode"
                    element={
                      <Suspense fallback={<Loader />}>
                        <PaymentMixingMastering />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="inbox/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <UserInbox />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="dashboard"
                    element={
                      <Suspense fallback={<Loader />}>
                        <UserDashboard />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="referral"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Referrals />
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="wallet"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Wallet />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="order-history/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <OrderHistory />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="order-history-mm/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <OrderHistoryMM />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="login/:type"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Login />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="verify-phone"
                    element={
                      <Suspense fallback={<Loader />}>
                        <VerifyPhone />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="verify-email"
                    element={
                      <Suspense fallback={<Loader />}>
                        <VerifyEmail />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="faqs"
                    element={
                      <Suspense fallback={<Loader />}>
                        <FAQPage />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="subscription/:name"
                    element={
                      <Suspense fallback={<Loader />}>
                        <SubscriptionForm />
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="create-payment"
                    element={
                      <Suspense fallback={<Loader />}>
                        <CreatePayment />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="jamrio-social"
                    element={
                      <Suspense fallback={<Loader />}>
                        <JamrioSocial />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="jamrio-social/post"
                    element={
                      <Suspense fallback={<Loader />}>
                        <UploadPost />
                      </Suspense>
                    }
                  />

                  <Route
                    exact
                    path="*"
                    element={
                      <Suspense fallback={<Loader />}>
                        <ErrorPage />
                      </Suspense>
                    }
                  />
                </Routes>
              </BrowserRouter>
            </NavigationProvider>
          </SortingProvider>
        </BookingDetailsProvider>
      </UserDetailsProvider>
    </div>
  );
}

export default App;
