import React, { useEffect, useState } from "react";
import "./styles.css";
import { ref, onValue } from "firebase/database";
import { db } from "../../config/index.js";
import OtpInput from "react-otp-input-rc-17";
import { FileUploader } from "react-drag-drop-files";
import ScaleLoader from "react-spinners/ScaleLoader";
import Rating from "@material-ui/lab/Rating";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaFileAudio } from "react-icons/fa";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import {
  CheckCircle,
  CheckCircleOutlineOutlined,
  Close,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";
import io from "socket.io-client";

const socket = io(
  `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/inbox`,
  {
    extraHeaders: {
      Authorization: window.localStorage.getItem("AuthToken"),
    },
  }
);

function OrderHistoryMM() {
  const { id } = useParams();
  const [LSUserId, setLSUserId] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [ratingValue, setRatingValue] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertReview, setShowAlertReview] = useState(false);
  const [reviewText, setReviewText] = useState();
  const [messageText, setMessageText] = useState();
  const [chatMessages, setChatMessages] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [engineerDetails, setEngineerDetails] = useState([]);
  const [engineerService, setEngineerService] = useState();
  const [orderMessages, setOrderMessages] = useState([]);
  const [inboxId, setInboxId] = useState([]);
  const [inboxes, setInboxes] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [progressStatus, setProgressStatus] = useState([]);
  const [orderProgressStatus, setOrderProgressStatus] = useState([]);
  const [orderStatusIds, setOrderStatusIds] = useState([]);

  //File Upload
  const fileTypes = [
    "PCM",
    "WAV",
    "MP3",
    "OGG",
    "FLAC",
    "WMA",
    "M4A",
    "AAC",
    "PNG",
    "JPG",
  ];
  const [file, setFile] = useState(null);
  const handleFileChange = (file) => {
    setFile(file);
    if (process.env.NODE_ENV === "development") {
      console.log("Selected file", file);
    }
  };

  //Setting up Toast function
  const notifyFileUpload = () => toast.success("File uploaded successfully!");
  const notifyReview = () => toast.success("Review added successfully!");

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    justifyContent: "center",
  };

  useEffect(() => {
    document.title = "Jamrio | Order History";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      window.localStorage.getItem("userId") !== null ||
      window.localStorage.getItem("userId") !== undefined ||
      window.localStorage.getItem("userId") !== ""
    ) {
      setLSUserId(window.localStorage.getItem("userId"));
    }
  }, []);

  useEffect(() => {
    // filter inbox id with studio id matcching the studio id of the order
    setInboxId([]);
    if (inboxes?.length > 0) {
      setInboxId(
        inboxes?.filter((inbox) => inbox.userId === orderDetails.userid)
      );
    }
  }, [inboxes, orderDetails]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("Inbox id", inboxId);
    }
  }, [inboxId]);

  useEffect(() => {
    FetchOrderDetails();
    GetUploadedFiles();
    GetInboxes();
    fetchInboxes();
    GetOrderProgressStatus();
    GetAllProgressStatus();
  }, []);

  useEffect(() => {
    fetchInboxes(engineerDetails?.id);
  }, [engineerDetails]);

  useEffect(() => {
    fetchEngineerDetails();
    fetchEngineerService();
  }, [orderDetails]);

  useEffect(() => {
    fetchUserData();
  }, [LSUserId]);

  useEffect(() => {
    setTimeout(() => setShowAlert(false), 4000);
  }, [showAlert]);
  useEffect(() => {
    setTimeout(() => setShowAlertReview(false), 4000);
  }, [showAlertReview]);

  const FetchOrderDetails = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/order/${id}/get`,
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("GET order details ----->", data);
          }
          setOrderDetails(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchEngineerDetails = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/${orderDetails?.engineer_id}/studioengineer`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("engineerData ----->", data);
          }
          setEngineerDetails(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  const fetchEngineerService = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/service/${orderDetails?.service_id}`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/engineer/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("engineer service Data ----->", data);
          }
          setEngineerService(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  // File upload api
  const UploadFile = async (data) => {
    const formData = new FormData();

    formData.append("file", data);

    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/${id}/files/upload/${orderDetails?.engineer_id}/user`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: formData,
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.isError) {
          if (process.env.NODE_ENV === "development") {
            console.log("File uploading ----->", data);
          }
          GetUploadedFiles();
          setUploading(false);
          setShowAlert(true);
          setFile(null);
          notifyFileUpload();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.isError);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  // Get uploaded files
  const GetUploadedFiles = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/${id}/files`,
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("GET uploaded files ----->", data);
          }
          setAllFiles(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  const fetchUserData = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/user/${window.localStorage.getItem("userId")}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (process.env.NODE_ENV === "development") {
          console.log("Response Body -> ", JSON.parse(JSON.stringify(data)));
        }

        if (!data.isError) {
          if (process.env.NODE_ENV === "development") {
            console.log("USER DETAILS ----->", data);
          }
          setUserDetails({
            name: data.name,
            phone: data.mobile,
            email: data.email,
          });
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.isError);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  const Review = async () => {
    //PRODUCTION
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/${orderDetails?.engineer_id}/reviews`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: `${window.localStorage.getItem("AuthToken")}`,
        },
        body: JSON.stringify({
          score: ratingValue,
          orderid: id,
          text: reviewText,
          name: userDetails.name,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          setReviewText("");
        }
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Transaction history ----->", data.data);
          }
          notifyReview();
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
          toast.error(data.message);
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  const GetInboxes = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/inbox/engineer/${engineerDetails?.id}`,
      // "http://localhost:3000/user/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("Inboxes ----->", data);
          }
          setInboxes(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed Inboxes", data);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  const fetchInboxes = async (engId) => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/inbox/engineer/${engId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("Inboxes DETAILS ----->", data);
          }
          setInboxes(data?.inbox);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed Inboxes ->>>", data);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  const DownloadFile = async (key) => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/${engineerDetails?.id}/files/get?file=${key}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (process.env.NODE_ENV === "development") {
          console.log("Response Body -> ", JSON.parse(JSON.stringify(data)));
        }

        if (!data.code) {
          if (process.env.NODE_ENV === "development") {
            console.log("File DETAILS ----->", data);
          }
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data?.message);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  const GetAllProgressStatus = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/progress`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("Progress Status ----->", data);
          }
          setProgressStatus(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data?.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetOrderProgressStatus = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/mixing-mastering/progress/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: window.localStorage.getItem("AuthToken"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("ORDER Progress Status ----->", data);
          }
          setOrderProgressStatus(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data?.message);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // -------------------Socket messaging------------------------
  useEffect(() => {
    socket.on("connect", () => {
      if (process.env.NODE_ENV === "development") {
        console.log("Connected");
      }
    });

    socket.on("error", (err) => {
      // setIsConnected(true);
      if (process.env.NODE_ENV === "development") {
        console.log("error", err);
      }
    });

    socket.on("send-message", (data) => {
      // setIsConnected(true);
      if (process.env.NODE_ENV === "development") {
        console.log("send message", data);
      }
      setMessageText("");
      handleClose();
      toast.success("Message sent successfully!");
    });

    socket.on("deliver-message", () => {
      if (process.env.NODE_ENV === "development") {
        console.log("deliver");
      }
    });

    socket.on("read-message", () => {
      if (process.env.NODE_ENV === "development") {
        console.log("read");
      }
    });

    socket.on("receive", (data) => {
      if (process.env.NODE_ENV === "development") {
        console.log("receive", data);
      }
    });

    socket.on("disconnect", () => {});

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  const sendMessage = () => {
    if (process.env.NODE_ENV === "development") {
      console.log(
        "Engineer id: ",
        engineerDetails?.id,
        messageText,
        orderDetails?.userid,
        "Order id:",
        orderDetails?.id
      );
    }
    if (messageText !== "") {
      socket.emit("send-message", {
        inbox: {
          inboxType: "engineerOrder",
          orderId: orderDetails?.id,
          engineerId: engineerDetails?.id,
          userId: orderDetails?.userid,
        },
        message: {
          messageType: "text",
          text: messageText,
          sender: "user",
          receiver: "engineer",
        },
      });
    } else {
      toast.error("Message cannot be empty");
    }
  };

  const submitReview = () => {
    if (ratingValue && reviewText) {
      Review();
    } else {
      alert("Please Rate the studio and add review text");
    }
  };

  const handlleSeeDetails = () => {
    window.localStorage.setItem(
      "studioId",
      window.localStorage.getItem("trnStudioId")
    );
    navigate(`/engineer-details/${orderDetails?.engineer_id}`);
  };

  let filledStars = parseInt(engineerDetails?.rating);

  useEffect(() => {
    if (orderProgressStatus?.length > 0) {
      GenerateArray(0, orderProgressStatus[0]?.status.id, 1);
    }
  }, [orderProgressStatus]);

  useEffect(() => {
    console.log("orderIDS", orderStatusIds);
  }, [orderStatusIds]);

  const GenerateArray = (start, stop, step) => {
    setOrderStatusIds([]);
    return Array.from({ length: stop }, (value, index) =>
      setOrderStatusIds((slots) => [...slots, index])
    );
  };

  const [orderEvents, setOrderEvents] = useState([
    {
      id: 1,
      name: "Files Received",
      status: false,
    },
    {
      id: 2,
      name: "In Production",
      status: true,
    },
    {
      id: 3,
      name: "Files Sent",
      status: false,
    },
    {
      id: 4,
      name: "Completed",
      status: true,
    },
  ]);

  return (
    <div className="orderHistory">
      <Helmet>
        <title>Jamrio | Order History</title>
        <meta
          name="description"
          content="Best place for booking and finding music studios, jampads,audio engineers, shoot locations and much more, Read blogs, Read Artist Testimonies and much more!"
        />
        <meta
          name="keywords"
          content="Music, Music Studios, Jampads, Audio Engineers, Book Studio, Shoot Locations"
        />
      </Helmet>
      <div className="mm-main-container">
        <div className="mm-order-right-main-container">
          <div className="mm-new-top-half">
            <div className="mm-order-right-top-container">
              <div className="mm-user-container">
                <div>
                  <img
                    id="mm-user-image"
                    src={engineerDetails?.image}
                    alt={engineerDetails.firstname}
                  />
                </div>
                <div className="mm-user-info">
                  <div>
                    <h4 className="mm-user-name">
                      {engineerDetails?.firstname +
                        " " +
                        engineerDetails?.lastname}
                    </h4>
                    <h2 className="mm-user-details">Mixing Engineer</h2>
                  </div>
                  <div>
                    {filledStars &&
                      [...Array(filledStars)].map((_, i) => (
                        <span key={i} className="filled-star">
                          &#9733;
                        </span>
                      ))}
                    {filledStars &&
                      [...Array(5 - filledStars)].map((_, i) => (
                        <span key={i} className="unfilled-star">
                          &#9734;
                        </span>
                      ))}
                  </div>
                </div>
              </div>

              <div className="order-details-text-content">
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    marginBottom: "10px",
                  }}
                >
                  Order Details
                </p>
                <div id="text-container">
                  <div className="order-details-content">
                    <p className="detail-title">Date </p>
                    <h1 className="detail">{orderDetails?.bookingdate}</h1>
                  </div>
                  <div className="order-details-content">
                    <p className="detail-title">Service </p>
                    <h1 className="detail">{engineerService?.service_name}</h1>
                  </div>
                  <div className="order-details-content">
                    <p className="detail-title">Payment Type </p>
                    <h1 className="detail">UPI</h1>
                  </div>
                  <div className="order-details-content">
                    <p className="detail-title">Amount Paid </p>
                    <h1 className="detail">
                      ₹{window.localStorage.getItem("cost")}
                    </h1>
                  </div>
                </div>
              </div>

              {/* <div className="request-status-container">
              <h2>OTP</h2>
              <OtpInput
                value={window.localStorage.getItem("otp")}
                numInputs={6}
                // separator={<span> - </span>}
                containerStyle={"otp-container"}
                inputStyle={"otp-input"}
              />
            </div> */}
            </div>

            <div className="mm-request-status-container">
              <div className="order-status-component">
                <p className="order-status-component-title">Order Status</p>
                <div className="order-status-component-content">
                  {progressStatus &&
                    progressStatus.map((event) => (
                      <div className="order-status-component-content-item">
                        {orderStatusIds.includes(event.order) ? (
                          <CheckCircle id="order-status-component-content-item-tick" />
                        ) : (
                          <CheckCircleOutlineOutlined id="order-status-component-content-item-tick" />
                        )}
                        <p className="order-status-component-content-item-value">
                          {event.name}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
              {/* <h2>Order Status</h2>
              <h1 className="engineer-Order-request-status">
                {orderDetails?.state === -1
                  ? "Pending"
                  : orderDetails?.state === 1
                  ? "Order Accepted"
                  : orderDetails?.state === 0
                  ? "Order Rejected"
                  : "Error"}
                {orderDetails?.state
                  ? orderDetails?.state?.split("_").join(" ")
                  : "Loading..."}
              </h1>
              <h3 id="order-id">{id}</h3> */}
            </div>
          </div>

          <div className="mm-end-container">
            <div className="mm-message-container">
              <h2 className="order-status-title">Message the Engineers</h2>
              {inboxId[0]?.id ? (
                <button
                  className="send-msg-btn"
                  onClick={() => navigate(`/inbox/${inboxId[0]?.id}`)}
                >
                  Open Inbox
                </button>
              ) : (
                <button className="send-msg-btn" onClick={handleOpen}>
                  Send Message
                </button>
              )}
            </div>
            {/* <di className="engineer-order-details-container"> */}
            {/* <div className="order-details-text-content">
              <div id="text-container">
                <div className="order-details-content">
                  <p className="detail-title">Order Date: </p>
                  <h1 className="detail">{orderDetails?.bookingdate}</h1>
                </div>
                <div className="order-details-content">
                  <p className="detail-title">Payment Type: </p>
                  <h1 className="detail">UPI</h1>
                </div>
                <div className="order-details-content">
                  <p className="detail-title">Amount Paid: </p>
                  <h1 className="detail">
                    ₹{window.localStorage.getItem("cost")}
                  </h1>
                </div>
              </div>
            </div> */}
            <div className="mm-help-container">
              <h2 style={{ margin: "0" }}>Help</h2>
              <div className="mm-order-details-btn-container">
                <button className="modalBtn">Call the studio</button>
                {/* <button className="modalBtn">Get directions</button> */}
                <button onClick={handlleSeeDetails} className="modalBtn">
                  Engineer Details
                </button>
              </div>
            </div>
            {/* </di> */}
          </div>

          {orderDetails?.state === "failed" ? null : orderDetails?.state ===
              "approval_pending" || orderDetails?.state === "success" ? (
            <div className="mm-middle-container">
              <div className="file-upload-container">
                <div className="file-upload-inner-container">
                  <div>
                    <FileUploader
                      handleChange={handleFileChange}
                      name="file"
                      types={fileTypes}
                      classes="file-upload-box"
                      maxSize={1024}
                      children={
                        <>
                          <h2>Drag and Drop a File here</h2>
                          <h2>OR</h2>
                          <h3 className="modalBtn modalBtn2">
                            Click here to select a file
                          </h3>
                          <p>Max file size 1GB</p>
                        </>
                      }
                    />
                  </div>
                </div>
                {file ? (
                  <>
                    <div className="selected-file-container">
                      <div className="selected-file">
                        <h1 className="detail">{file.name}</h1>
                      </div>
                    </div>
                    <button
                      className="modalBtn modalBtn3"
                      onClick={() => {
                        setUploading(true);
                        UploadFile(file);
                      }}
                    >
                      {uploading ? (
                        <div>
                          <ScaleLoader
                            color={"#fff"}
                            loading={uploading}
                            height={20}
                            width={5}
                            radius={50}
                          />
                        </div>
                      ) : (
                        <p>Upload File</p>
                      )}
                    </button>
                  </>
                ) : null}
                <ToastContainer
                  position="bottom-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />
                {allFiles ? (
                  <div className="uploaded-file-container">
                    <h3>Uploaded Files</h3>
                    <div className="file-container">
                      {allFiles.map((file) => {
                        let fileName = file.filename;
                        return (
                          <div
                            className="uploaded-file"
                            key={file.url}
                            // onClick={() => {
                            //   DownloadFile(file.file_key);
                            // }}
                          >
                            <a
                              onClick={() =>
                                console.log(
                                  file.url
                                    .split("?")[0]
                                    ?.replace(
                                      "https://jamrioapp.s3.ap-south-1.amazonaws.com",
                                      "files.jamrio.com"
                                    )
                                )
                              }
                              download={file.url
                                .split("?")[0]
                                ?.replace(
                                  "https://jamrioapp.s3.ap-south-1.amazonaws.com",
                                  "files.jamrio.com"
                                )}
                              className="file-link"
                              href="/"
                              target={"_blank"}
                            >
                              <FaFileAudio size={26} color={"#888"} />
                              <p className="file-name">
                                {fileName?.slice(0, -4).substring(0, 20) +
                                  "..."}
                              </p>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Message Studio
                  </Typography>
                  <input
                    id="order-history-chat-input"
                    type="text"
                    placeholder="Type your message ..."
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                  />
                  <button className="send-msg-btn" onClick={sendMessage}>
                    Send Message
                  </button>
                </Box>
              </Modal>

              <div className="mm-review-container">
                <div className="review-items-container">
                  <div className="review-text-content">
                    <div className="review-box">
                      <div className="mm-review-top">
                        <h2 style={{ width: "100%", margin: "0" }}>
                          Give Review
                        </h2>
                        <div className="review-stars-container">
                          <h2 style={{ margin: "0" }}>Rate Engineer</h2>
                          <Rating
                            name="no-value"
                            value={ratingValue}
                            onChange={(event, newValue) => {
                              if (process.env.NODE_ENV === "development") {
                                console.log("Ratings", newValue);
                              }
                              setRatingValue(newValue);
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                      <form>
                        <textarea
                          rows={6}
                          name="review"
                          placeholder="Write Review"
                          className="textarea"
                          value={reviewText}
                          onChange={(text) => {
                            if (process.env.NODE_ENV === "development") {
                              console.log("Review", text.target.value);
                            }
                            setReviewText(text.target.value);
                          }}
                        ></textarea>
                      </form>
                    </div>
                  </div>
                </div>
                <button className="modalBtn modalBtn2" onClick={submitReview}>
                  Submit Review
                </button>
              </div>
            </div>
          ) : orderDetails?.state === "failed" ? (
            <div className="rebooking-container">
              <div className="rebooking-inner-container">
                <h1 className="detail rebooking-text">
                  The Engineer has rejected your order. Please Rebook your order
                  with a different package
                </h1>
                <button className="modalBtn modalBtn2" onClick={submitReview}>
                  Rebook
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default OrderHistoryMM;
