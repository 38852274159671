const SlotsData = [
  {
    id: 9,
    start: 9,
    end: 10,
    period: "am",
  },
  {
    id: 10,
    start: 10,
    end: 11,
    period: "am",
  },
  {
    id: 11,
    start: 11,
    end: 12,
    period: "am",
  },
  {
    id: 12,
    start: 12,
    end: 13,
    period: "pm",
  },
  {
    id: 13,
    start: 13,
    end: 14,
    period: "pm",
  },
  {
    id: 14,
    start: 14,
    end: 15,
    period: "pm",
  },
  {
    id: 15,
    start: 15,
    end: 16,
    period: "pm",
  },
  {
    id: 16,
    start: 16,
    end: 17,
    period: "pm",
  },
  {
    id: 17,
    start: 17,
    end: 18,
    period: "pm",
  },
  {
    id: 18,
    start: 18,
    end: 19,
    period: "pm",
  },
  {
    id: 19,
    start: 19,
    end: 20,
    period: "pm",
  },
  {
    id: 20,
    start: 20,
    end: 21,
    period: "pm",
  },
];

export default SlotsData;
