import React, { useEffect, useState } from 'react';
import './Reviews.css';
import { Box, Button, Modal, Typography } from '@mui/material';
import { BsChevronRight } from 'react-icons/bs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    // bottom: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: 600,
    overflow: 'auto',
    display: 'block'
};

const Reviews = ({ reviews }) => {
    // const reviews1 = [
    //     {
    //         name: "User1",
    //         score: 3,
    //         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nesciunt, nulla assumenda deserunt ratione voluptates aperiam cupiditate deleniti aut nam impedit omnis perferendis quia recusandae facere doloribus! Sapiente, vero!"
    //     },
    //     {
    //         name: "User1",
    //         score: 3,
    //         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nesciunt, nulla assumenda deserunt ratione voluptates aperiam cupiditate deleniti aut nam impedit omnis perferendis quia recusandae facere doloribus! Sapiente, vero!"
    //     },
    //     {
    //         name: "User1",
    //         score: 3,
    //         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nesciunt, nulla assumenda deserunt ratione voluptates aperiam cupiditate deleniti aut nam impedit omnis perferendis quia recusandae facere doloribus! Sapiente, vero!"
    //     },
    //     {
    //         name: "User1",
    //         score: 3,
    //         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nesciunt, nulla assumenda deserunt ratione voluptates aperiam cupiditate deleniti aut nam impedit omnis perferendis quia recusandae facere doloribus! Sapiente, vero!"
    //     },
    //     {
    //         name: "User1",
    //         score: 3,
    //         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nesciunt, nulla assumenda deserunt ratione voluptates aperiam cupiditate deleniti aut nam impedit omnis perferendis quia recusandae facere doloribus! Sapiente, vero!"
    //     },
    // ];
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [allReviews, setAllReviews] = useState(reviews);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", changeWidth);
    }, []);






    useEffect(() => {
        if (screenWidth >= 600) {
            setAllReviews(reviews.slice(0, 3));
        }
        else {
            setAllReviews(reviews);
        }
    }, [screenWidth, reviews]);

    return (
        <div className='top-review-container'>
            {allReviews?.length > 0 ? (
                allReviews?.map((review) => (
                    <div className="review-container">
                        <img
                            src="https://img.icons8.com/material-rounded/100/000000/user-male-circle.png"
                            alt="reviewer"
                            id="review-img"
                        />
                        <div className="review-right">
                            <p id="name-text">
                                {review?.name}
                            </p>
                            <p id="score-text">
                                ⭐ {review?.score}
                            </p>
                            <p id='review-text'>{review?.text}</p>
                        </div>
                    </div>
                )
                )
            ) : (
                <p>No Reviews Yet</p>
            )}
            {
                (screenWidth >= 600 && reviews.length>3) && <div>
                    {/* <Button onClick={handleOpen}><BsChevronRight/></Button> */}
                    <Button onClick={handleOpen}>See All</Button>
                </div>
            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='modal-review'>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Reviews
                    </Typography>
                    <div style={{ marginTop: "20px" }}>
                        {reviews?.length > 0 ? (
                            reviews.map((review) => (
                                <div style={{ width: '95%', marginBottom: '15px' }} className="review-container">
                                    <img
                                        src="https://img.icons8.com/material-rounded/100/000000/user-male-circle.png"
                                        alt="reviewer"
                                        id="review-img"
                                    />
                                    <div className="review-right">
                                        <p id="name-text">
                                            {review?.name}
                                        </p>
                                        <p id="score-text">
                                            ⭐ {review?.score}
                                        </p>
                                        <p id='review-text'>{review?.text}</p>
                                    </div>
                                </div>
                            )
                            )
                        ) : (
                            <p>No Reviews Yet</p>
                        )}
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default Reviews;