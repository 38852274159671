import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notifyErr, notifyRegistrationSuccess } from '../../components/ToastFunctions';
import loginImg from "../../Images/login-mockup-img.webp";
import { Helmet } from 'react-helmet';
import { Box } from '@mui/system';
import { TextField } from '@mui/material';
import styled from '@emotion/styled';
import { HStack, PinInput, PinInputField } from '@chakra-ui/react';
import { FaSpinner } from 'react-icons/fa';
import './styles.css';

const TextBox = styled(TextField)(() => ({
    "& fieldset": {
        boxShadow: "0 0 10px rgba(0,0,0,0.2)",
        borderRadius: "10px",
        border: "none",
    },
}));

const VerifyEmail = () => {
    let navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [userDetails, setUserDetails] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
        username: "",
        referralCode: "",
    });
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(0);
    const [disableBtn, setDisableBtn] = useState(false);
    const [otp, setOtp] = useState("");
    const [showOTP, setShowOTp] = useState(false);

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", changeWidth);
    }, []);

    const requestOTP = (e) => {
        e.preventDefault();
        fetch(
            `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/auth/login/phone/${userDetails.phone}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (process.env.NODE_ENV === "development") {
                    console.log(data);
                }
                if (data.token) {
                    if (process.env.NODE_ENV === "development") {
                        console.log("Success");
                    }
                    setLoading(false);
                    setToken(data.token);
                    notifyRegistrationSuccess(data.message);
                } else {
                    if (process.env.NODE_ENV === "development") {
                        console.log("Failed", data);
                    }
                    notifyErr(data.message);
                    setLoading(false);
                    setDisableBtn(false);
                }
            })
            .catch((error) => {
                if (process.env.NODE_ENV === "development") {
                    console.error(error);
                }
            });
    };

    const verifyOTP = (e) => {
        e.preventDefault();
        if (process.env.NODE_ENV === "development") {
            console.log("TOKEN", token);
        }
        fetch(
            `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/auth/login/phone/${userDetails.phone}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: token,
                    otp: otp,
                }),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (process.env.NODE_ENV === "development") {
                    console.log(data);
                }
                if (data.data) {
                    if (process.env.NODE_ENV === "development") {
                        console.log("Suuccess");
                    }
                    window.localStorage.setItem("userId", data.data.userId);
                    window.localStorage.setItem("AuthToken", data.data.token);
                    notifyRegistrationSuccess(data.message);
                    navigate("/");
                } else {
                    if (process.env.NODE_ENV === "development") {
                        console.log("Failes", data);
                    }
                    notifyErr(data.message);
                }
            })
            .catch((error) => {
                if (process.env.NODE_ENV === "development") {
                    console.error(error);
                }
            });
    };

    return (
        <div className="login-container">
            <Helmet>
                <title>Jamrio | Verify Email</title>
                <meta
                    name="description"
                    content="Best place for booking and finding music studios, jampads,audio engineers, shoot locations and much more, Read blogs, Read Artist Testimonies and much more!"
                />
                <meta
                    name="keywords"
                    content="Music, Music Studios, Jampads, Audio Engineers, Book Studio, Shoot Locations"
                />
            </Helmet>
            <div className="login-left">
                <img
                    src={loginImg}
                    alt="Login-BGHigher-Res"
                    className="login-left-img"
                />
            </div>
            <div className="login-right">
                {screenWidth > 900 ? (
                    <form className="login-form">
                        {/* <div className="login-nav">
                            <div
                                onClick={() => {
                                    navHandler("login");
                                }}
                                className={
                                    navItem === "login"
                                        ? "login-nav-item-selected"
                                        : "login-nav-item"
                                }
                            >
                                <p className="login-nav-text">Login</p>
                            </div>
                            <div
                                onClick={() => {
                                    navHandler("register");
                                }}
                                className={
                                    navItem === "register"
                                        ? "login-nav-item-selected"
                                        : "login-nav-item"
                                }
                            >
                                <p className="login-nav-text">Register</p>
                            </div>
                        </div> */}
                        <div className="login-title-container">
                            <p className="login-title">Verify Your Email Address</p>
                            <p className="login-description">
                                Explore all the most exiting music services based on your music
                                interest
                            </p>
                        </div>
                        <div className="inputs">
                            <Box
                                component="form"
                                sx={{
                                    "& .MuiTextField-root": {
                                        marginBottom: "30px",
                                        width: "100%",
                                    },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div className="mobile-number">
                                    <div className="register-mobile-number">

                                        <TextBox
                                            required
                                            className="outlined-error-helper-text"
                                            variant="outlined"
                                            // className="mobile-number-input"
                                            // type="number"
                                            label="Enter Email Address"
                                            value={userDetails.email}
                                            onChange={(e) =>
                                                setUserDetails({
                                                    ...userDetails,
                                                    email: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    {/* {console.log(userDetails)} */}
                                    {/* {userDetails.phone.length === 10 && ( */}
                                    <button
                                        disabled={disableBtn}
                                        onClick={(e) => {
                                            requestOTP(e);
                                            setDisableBtn(true);
                                            setLoading(true);
                                            setShowOTp(true);
                                        }}
                                        className="send-otp-btn"
                                        id="timer"
                                    >
                                        {loading ? (
                                            <FaSpinner icon="spinner" className="spinner" />
                                        ) : (
                                            "Request OTP"
                                        )}
                                    </button>

                                </div>
                                <div>
                                    <HStack
                                        className={!disableBtn ? "hidden-otp" : "pin-container"}
                                    >
                                        <PinInput
                                            placeholder="•"
                                            size="sm"
                                            otp
                                            onChange={(e) => setOtp(e)}
                                        >
                                            <PinInputField className={!disableBtn ? "hidden-otp" :"pin-input"} />
                                            <PinInputField className={!disableBtn ? "hidden-otp" :"pin-input"} />
                                            <PinInputField className={!disableBtn ? "hidden-otp" :"pin-input"} />
                                            <PinInputField className={!disableBtn ? "hidden-otp" :"pin-input"} />
                                            <PinInputField className={!disableBtn ? "hidden-otp" :"pin-input"} />
                                            <PinInputField className={!disableBtn ? "hidden-otp" :"pin-input"} />
                                        </PinInput>
                                    </HStack>
                                </div>
                            </Box>
                        </div>
                        <button
                            className={!disableBtn ? "hidden-otp" : "login-page-btn"}
                            onClick={verifyOTP}
                        >
                            Submit
                        </button>
                        {/* <Divider
                            sx={{
                                marginTop: "23px",
                                marginBottom: "20px",
                                fontWeight: "700",
                                fontSize: "20px",
                            }}
                        >
                            OR
                        </Divider> */}

                    </form>
                ) : (
                    <form className="login-form">
                        {/* <div className="login-nav">
                            <div
                                onClick={() => {
                                    navHandler("login");
                                }}
                                className={
                                    navItem === "login"
                                        ? "login-nav-item-selected"
                                        : "login-nav-item"
                                }
                            >
                                <p className="login-nav-text">Login</p>
                            </div>
                            <div
                                onClick={() => {
                                    navHandler("register");
                                }}
                                className={
                                    navItem === "register"
                                        ? "login-nav-item-selected"
                                        : "login-nav-item"
                                }
                            >
                                <p className="login-nav-text">Register</p>
                            </div>
                        </div> */}

                        <div className="login-title-container">
                            <p className="login-title">Verify Your Email Address</p>
                            <p className="login-description">
                                Explore all the most exiting music services based on your music
                                interest
                            </p>
                        </div>

                        <div className="inputs">
                            <Box
                                component="form"
                                sx={{
                                    "& .MuiTextField-root": {
                                        marginBottom: "30px",
                                        width: "100%",
                                    },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <div className="mobile-number">
                                    <div className="register-mobile-number">

                                        <TextBox
                                            required
                                            className="outlined-error-helper-text"
                                            variant="outlined"
                                            // className="mobile-number-input"
                                            type="email"
                                            label="Email Address"
                                            value={userDetails.email}
                                            onChange={(e) =>
                                                setUserDetails({
                                                    ...userDetails,
                                                    email: e.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                    <button
                                        disabled={disableBtn}
                                        onClick={(e) => {
                                            requestOTP(e);
                                            setDisableBtn(true);
                                            setLoading(true);
                                            setShowOTp(true);
                                        }}
                                        className="send-otp-btn"
                                        id="timer"
                                    >
                                        {loading ? (
                                            <FaSpinner icon="spinner" className="spinner" />
                                        ) : (
                                            "Request OTP"
                                        )}
                                    </button>

                                </div>
                                <div>
                                    <HStack
                                        className={!disableBtn ? "hidden-otp" : "pin-container"}
                                    >
                                        <PinInput
                                            placeholder="•"
                                            size="sm"
                                            otp
                                            onChange={(e) => setOtp(e)}
                                        >
                                            <PinInputField className={!disableBtn ? "hidden-otp" :"pin-input"} />
                                            <PinInputField className={!disableBtn ? "hidden-otp" :"pin-input"} />
                                            <PinInputField className={!disableBtn ? "hidden-otp" :"pin-input"} />
                                            <PinInputField className={!disableBtn ? "hidden-otp" :"pin-input"} />
                                            <PinInputField className={!disableBtn ? "hidden-otp" :"pin-input"} />
                                            <PinInputField className={!disableBtn ? "hidden-otp" :"pin-input"} />
                                        </PinInput>
                                    </HStack>
                                </div>
                                <button
                                    className={!disableBtn ? "hidden-otp" : "login-page-btn"}
                                    onClick={verifyOTP}
                                >
                                    Submit
                                </button>
                                {/* <Divider
                                    sx={{
                                        marginTop: "23px",
                                        marginBottom: "20px",
                                        fontWeight: "700",
                                        fontSize: "20px",
                                    }}
                                >
                                    OR
                                </Divider> */}

                            </Box>
                        </div>
                    </form>
                )}
                <div id="recaptcha-container"></div>
            </div>
        </div>)
};

export default VerifyEmail;