import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  notifySlotsErr,
  notifyCheckboxErr,
  notifyErr,
} from "../../components/ToastFunctions";
import SlotsData from "../../Data/SlotsData";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  Checkbox,
  Modal,
  Box,
  Typography,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Add, ExpandMore } from "@mui/icons-material";
import { orange } from "@mui/material/colors";
import { toast, ToastContainer } from "react-toastify";
import placeholderImage from "../../Images/noImg.webp";
import StudioContainerListing from "../../components/StudioContainers/StudioContainerListing";
import { Close } from "@mui/icons-material";
import Countdown from "react-countdown";
import { io } from "socket.io-client";
import Reviews from "../../components/Reviews/Reviews";
const color = "#FF782C";
const socket = io(
  `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/live-booking`
);

const slotsResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1200, min: 700 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 3,
  },
};

const studiosResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1200, min: 900 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 900, min: 0 },
    items: 1,
  },
};

const SlotsComponent = ({
  selectedSlots,
  onSlotClick,
  screenWidthChanged,
  bookedSlots,
  selectedDate,
  slots,
  liveBookingArray,
}) => {
  return (
    <div className="studio-booking-slots-container">
      {/* {!screenWidthChanged ? ( */}

      <Carousel
        containerClass="studio-booking-slots-inner-container"
        responsive={slotsResponsive}
      >
        {slots.slice(0, slots.length - 1).map((slot, index) => (
          <div className="studio-booking-slot-items" key={index}>
            <Tooltip
              arrow
              title={
                bookedSlots?.includes(slot.start)
                  ? "Slot already booked"
                  : selectedSlots?.includes(slot.start)
                  ? "Slot selected"
                  : liveBookingArray?.includes(slot.start + 1)
                  ? "Slot being booked by another user right now"
                  : "Slot available"
              }
            >
              <span>
                <button
                  onClick={() => onSlotClick(slot.start)}
                  className={[
                    bookedSlots?.includes(slot.start)
                      ? "disabled-slot-square"
                      : selectedSlots?.includes(slot.start)
                      ? "studio-slot-clicked"
                      : liveBookingArray?.includes(slot.start + 1)
                      ? "studio-slot-live"
                      : "studio-booking-slot-square",
                  ]}
                  disabled={
                    bookedSlots?.includes(slot.start) ||
                    liveBookingArray?.includes(slot.start + 1)
                  }
                ></button>
              </span>
            </Tooltip>
            <p>
              {slot.start > 12
                ? slot.start - 12 + "pm"
                : slot.start === 12
                ? slot.start + "pm"
                : slot.start + "am"}{" "}
              -{" "}
              {slot.end > 12
                ? slot.end - 12 + "pm"
                : slot.end === 12
                ? slot.end + "pm"
                : slot.end + "am"}
            </p>
          </div>
        ))}
      </Carousel>
      {/* ) : ( */}
    </div>
  );
};

const BookingComponent = ({
  screenWidthChanged,
  slots,
  onSlotClick,
  selectedSlots,
  bookedSlots,
  selectedDate,
  liveBookingArray,

  selectedCheckbox,
  studioData,
  setSelectedDate,
  endTime,
  startTime,
  packages,
  setPackageClicked,
  packageClicked,
  setPackageSelected,
  packageSelected,
  proceedBooking,
  setPackageCost,
  setPackageHours,
  setSelectedCheckbox,
}) => (
  <div className="studio-booking-container">
    <div className="studio-details-cost-container">
      {/* <h1 id="studio-details-title">
        ₹
        {selectedCheckbox.jampad
          ? selectedSlots?.length === 0
            ? studioData?.jampadprice
            : selectedSlots?.length * studioData?.jampadprice
          : selectedSlots?.length === 0
          ? studioData?.studioprice
          : selectedSlots?.length * studioData?.studioprice}
      </h1>{" "} */}
    </div>
    <div className="studio-details-date-time-container">
      {/* <div className="studio-details-date-container"> */}
      <DatePicker
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        className="studio-booking-date-calendar"
        minDate={moment().toDate()}
        dateFormat="dd MMMM yyyy"
        required={true}
      />
      <div className="studio-details-time-container">
        <div className="studio-details-start-time-container">
          <p className="studio-details-slot-text">
            {startTime
              ? parseInt(startTime) > 12
                ? parseInt(startTime) - 12 + "pm"
                : parseInt(startTime) === 12
                ? parseInt(startTime) + "pm"
                : parseInt(startTime) + "am"
              : "00:00"}
          </p>
        </div>
        <div className="studio-details-end-time-container">
          <p className="studio-details-slot-text">
            {endTime
              ? parseInt(endTime) > 12
                ? endTime - 12 + "pm"
                : parseInt(endTime) === 12
                ? parseInt(endTime) + "pm"
                : parseInt(endTime) + "am"
              : "00:00"}
          </p>
        </div>
      </div>
    </div>
    {/* <div className="studio-booking-slots-container"></div> */}
    <SlotsComponent
      screenWidthChanged={screenWidthChanged}
      slots={slots}
      onSlotClick={onSlotClick}
      selectedSlots={selectedSlots}
      bookedSlots={bookedSlots}
      selectedDate={selectedDate}
      liveBookingArray={liveBookingArray}
    />
    <div className="studio-details-attendees-main-container">
      {studioData?.studioprice > 0 ? (
        <div
          className={
            selectedCheckbox.studio
              ? "option-selection-inner-selected"
              : "option-selection-inner-container"
          }
        >
          <div className="option-details-container">
            <Checkbox
              checked={selectedCheckbox.studio}
              className="checkbox"
              sx={{
                color: orange[600],
                "&.Mui-checked": {
                  color: orange[600],
                },
                "& .MuiSvgIcon-root": { fontSize: 40 },
              }}
              onChange={(e) => {
                setSelectedCheckbox({
                  ...selectedCheckbox,
                  studio: e.target.checked,
                  jampad: false,
                });
              }}
            />
            <h3 className="option-name">
              Book Studio ₹{studioData?.studioprice}/hr
            </h3>
          </div>
        </div>
      ) : null}
      {studioData?.jampadprice > 0 ? (
        <div
          className={
            selectedCheckbox.jampad
              ? "option-selection-inner-selected"
              : "option-selection-inner-container"
          }
        >
          <div className="option-details-container">
            <Checkbox
              checked={selectedCheckbox.jampad}
              className="checkbox"
              sx={{
                color: orange[600],
                "&.Mui-checked": {
                  color: orange[600],
                },
                "& .MuiSvgIcon-root": { fontSize: 40 },
              }}
              onChange={(e) => {
                setSelectedCheckbox({
                  ...selectedCheckbox,
                  jampad: e.target.checked,
                  studio: false,
                });
              }}
            />
            <h3 className="option-name">
              Book Jampad ₹{studioData?.jampadprice}/hr
            </h3>
          </div>
        </div>
      ) : null}
    </div>
    {packages?.length > 0 && (
      <div className="studio-details-attendees-main-container">
        <h1 id="studio-details-title">Additional Packages</h1>
        <div className="studio-details-packages-container">
          {packages?.length > 0
            ? packages?.map((pkg) => (
                <div
                  onClick={() => {
                    setPackageClicked(!packageClicked);
                    setPackageSelected(pkg.id);
                    setPackageCost(pkg.cost);
                    setPackageHours(pkg.hours_allowed);
                  }}
                  className={`location-package${
                    packageSelected === pkg.id ? "-selected" : ""
                  }`}
                >
                  <p id="studio-package-name">{pkg?.name}</p>
                  <p id="studio-package-desc">{pkg?.description}</p>
                  <p id="studio-package-cost">
                    ₹{pkg?.cost} for {pkg.hours_allowed}hrs
                  </p>
                </div>
              ))
            : null}
          {packageSelected !== "" && (
            <button
              id="cancel-package"
              onClick={() => {
                setPackageSelected("");
                setPackageClicked(false);
              }}
            >
              Cancel Package
            </button>
          )}
        </div>
      </div>
    )}
    <button
      onClick={() => {
        proceedBooking();
      }}
      id="studio-details-booking-btn"
    >
      Book Now
    </button>
  </div>
);

const StudioDetailsNew = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [studioData, setStudioData] = useState();
  const [studiosList, setStudiosList] = useState([]);
  const [studios, setStudios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [equipmentData, setEquipmentData] = useState();
  const [studioImages, setStudioImages] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [avgRatings, setAvgRatings] = useState();
  const [coupons, setCoupons] = useState();
  const [couponClicked, setCouponClicked] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState();
  const [couponCost, setCouponCost] = useState(0);
  const [isFinalAmount, setIsFinalAmount] = useState(false);
  const [packages, setPackages] = useState([]);
  const [packageClicked, setPackageClicked] = useState(false);
  const [packageSelected, setPackageSelected] = useState("");
  const [packageHours, setPackageHours] = useState(0);
  const [packageCost, setPackageCost] = useState(0);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [bookedSlotsArray, setBookedSlotsArray] = useState([]);
  const [liveBooking, setLiveBooking] = useState([]);
  const [liveBookingArray, setLiveBookingArray] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [selectedCheckbox, setSelectedCheckbox] = useState({
    studio: true,
    jampad: false,
  });
  const [previewDetails, setPreviewDetails] = useState({
    start: startTime,
    end: endTime,
    date: selectedDate,
    cost: 0,
  });
  const [LSPreviewDetails, setLSPreviewDetails] = useState(
    JSON.parse(window.localStorage.getItem("previewDetails"))
  );
  const [operationalHrs, setOperationalHrs] = useState([]);
  const [weekendOff, setWeekendOff] = useState(false);
  const [slots, setSlots] = useState([]);
  const [generatedSlots, setGeneratedSlots] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setFloatingBtn(false);
    setOpen(true);
  };
  const handleClose = () => {
    setFloatingBtn(true);
    setOpen(false);
  };
  const [floatingBtn, setFloatingBtn] = useState(true);
  const [hostRules, setHostRules] = useState([]);
  const { id } = useParams();
  let navigate = useNavigate();

  const modalStyle = {
    position: "absolute",
    bottom: "50px",
    left: "50%",
    transform: "translate(-50%, 0)",
    width: "100%",
    maxWidth: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    boxSizing: "border-box",
    borderRadius: 1,
    justifyContent: "center",
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);
  }, []);

  useEffect(() => {
    socket.on("connect", () => {
      if (process.env.NODE_ENV === "development") {
        console.log("Connected");
      }
    });

    socket.on("error", (err) => {
      // setIsConnected(true);
      if (process.env.NODE_ENV === "development") {
        console.log("error", err);
      }
    });

    socket.on("booking", (data) => {
      // setIsConnected(true);
      if (process.env.NODE_ENV === "development") {
        console.log("slots blocked", data);
      }
      setLiveBooking(data);
    });

    socket.on("disconnect", () => {});

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchStudioData(id);
    fetchOperationalHrs(id);
    fetchHostRules(id);
  }, [id]);

  useEffect(() => {
    GetTodaysSlots();
  }, [operationalHrs, selectedDate]);

  function GetTodaysSlots(params) {
    if (process.env.NODE_ENV === "development") {
      console.log("show operational hrs", operationalHrs);
      console.log(
        "show operational hrs is weekend",
        operationalHrs?.isWeekendOff
      );
      console.log("show day", moment(selectedDate).format("dddd"));
    }
    if (
      Object.entries(operationalHrs)?.filter(
        (item) => item[0] === "isWeekendOff" && item[1] === false
      )
    ) {
      if (process.env.NODE_ENV === "development") {
        console.log("it is not weekend off");
        console.log(
          "filter operational hrs",
          Object.entries(operationalHrs)?.filter(
            (item) =>
              item[0]?.substring(1, 5) ===
              moment(selectedDate).format("dddd").substring(1, 5)
          )[0]
        );
      }
      let newOpHrs = Object.entries(operationalHrs)?.filter(
        (item) =>
          item[0]?.substring(1, 5) ===
          moment(selectedDate).format("dddd").substring(1, 5)
      )[0];
      if (process.env.NODE_ENV === "development") {
        console.log("newOpHrs", newOpHrs);
      }
      // ////////////////
      if (newOpHrs?.length > 1) {
        const start = parseInt(newOpHrs[1]?.from?.substring(0, 2));
        const end = parseInt(newOpHrs[1]?.to?.substring(0, 2));
        if (process.env.NODE_ENV === "development") {
          console.log("start", start);
          console.log("end", end);
        }
        return !isNaN(start) && !isNaN(end)
          ? GenerateSlotsArray(start, end, 1)
          : setGeneratedSlots([]);
      }
    }
  }

  // Generate array from range
  const GenerateArray = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("Generated slots", generatedSlots);
    }
  }, [generatedSlots]);

  const GenerateSlotsArray = (start, stop, step) => {
    setGeneratedSlots([]);
    return Array.from({ length: (stop - start) / step + 1 }, (value, index) =>
      setGeneratedSlots((slots) => [
        ...slots,
        {
          id: index,
          start: start + index * step,
          end: start + index * step + 1,
        },
      ])
    );
  };

  // TODO: 1) Check studio ID of the live booking slots 2)Fetch live booking slots according to the date. 3) Filter the slots according to the date selected and blocked slots and slots being booked.

  useEffect(() => {
    fetchBookedSlots();
    const slotStart =
      new Date().toISOString().substring(0, 10).split("-").join("") ===
        new Date(selectedDate)
          .toISOString()
          .substring(0, 10)
          .split("-")
          .join("") && moment().hour();
    if (process.env.NODE_ENV === "development") {
      console.log("slot start", slotStart);
    }
    if (slotStart) {
      setSlots(
        generatedSlots.filter((s) => {
          if (process.env.NODE_ENV === "development") {
            console.log("s.id > slotStart", s.start, slotStart);
          }
          return s.start > slotStart;
        })
      );
      if (process.env.NODE_ENV === "development") {
        console.log("filtered slots------>", slots);
      }
    } else {
      setSlots(generatedSlots);
      if (process.env.NODE_ENV === "development") {
        console.log("filtered slots------>", slots);
      }
    }
    socket.on("booking", (data) => {
      // setIsConnected(true);
      if (process.env.NODE_ENV === "development") {
        console.log("slots blocked", data);
      }
      setLiveBooking(data);
    });
    CreateLiveBookingSlots();
  }, [selectedDate, generatedSlots]);

  useEffect(() => {
    createBookedSlotsArray();
  }, [bookedSlots]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("booked slots array", bookedSlotsArray);
    }
  }, [bookedSlotsArray]);

  // create an array of booked slots
  const createBookedSlotsArray = () => {
    setBookedSlotsArray([]);
    bookedSlots?.forEach((slot) => {
      if (
        moment(selectedDate).format("YYYY-MM-DD") ===
        slot.bookingDate?.substring(0, 10)
      ) {
        const slotStart = parseInt(slot.fromHour.substring(0, 2));
        const slotEnd = parseInt(slot.toHour.substring(0, 2));
        if (slotStart !== 0) {
          GenerateArray(slotStart, slotEnd - 1, 1).forEach((s) => {
            setBookedSlotsArray((slots) => [...slots, s]);
          });
        }
      }
    });
  };

  // generate array of live booking slots
  const CreateLiveBookingSlots = () => {
    return liveBooking.forEach((slot) => {
      if (
        moment(selectedDate).format("YYYY-MM-DD") ===
        slot.bookingdate?.substring(0, 10)
      ) {
        if (process.env.NODE_ENV === "development") {
          console.log(
            "Booking date matches",
            moment(selectedDate).format("YYYY-MM-DD"),
            slot.bookingdate?.substring(0, 10)
          );

          console.log("Slot", slot);
        }
        const slotStart = parseInt(slot.startTime.substring(0, 2));
        const slotEnd = parseInt(slot.endTime.substring(0, 2));
        if (process.env.NODE_ENV === "development") {
          console.log("Slot start", slotStart);
          console.log("Slot end", slotEnd);
        }
        if (slotStart !== 0) {
          const slotArray = GenerateArray(slotStart, slotEnd, 1);
          if (process.env.NODE_ENV === "development") {
            console.log("Slot array", slotArray);
          }
          return setLiveBookingArray(slotArray);
        }
      } else {
        if (process.env.NODE_ENV === "development") {
          console.log(
            "Booking date doesn't match",
            moment(selectedDate).format("YYYY-MM-DD"),
            slot.bookingdate?.substring(0, 10)
          );
        }
      }
    });
  };

  useEffect(() => {
    getStartEndTime();
  }, [selectedSlots]);

  useEffect(() => {
    setPackageClicked(false);
  }, [couponClicked]);

  useEffect(() => {
    setCouponClicked(false);
  }, [packageClicked]);

  useEffect(() => {
    document.title = "Jamrio | Studios";
    window.scrollTo(0, 0);
    fetchStudioList();
  }, []);

  useEffect(() => {
    setPreviewDetails({
      start: startTime,
      end: endTime,
      date: selectedDate,
      cost: studioData?.studioprice || studioData?.jampadprice,
    });
  }, [startTime, endTime, selectedDate, studioData]);

  useEffect(() => {
    if (previewDetails.start !== "")
      window.localStorage.setItem(
        "previewDetails",
        JSON.stringify(previewDetails)
      );
    setLSPreviewDetails(
      JSON.parse(window.localStorage.getItem("previewDetails"))
    );
    // Remove the item from localStorage after 5 mins
    setTimeout(() => {
      window.localStorage.removeItem("previewDetails");
    }, 300000);
  }, [previewDetails]);

  useEffect(() => {
    setWeekendOff(operationalHrs["isWeekendOff"] || false);
  }, [operationalHrs]);

  useEffect(() => {
    if (studioData?.jampadprice > 0 && !studioData?.studioprice > 0) {
      setSelectedCheckbox({
        studio: false,
        jampad: true,
      });
    }
  }, [studioData]);

  //Fetch Studio List
  const fetchStudioList = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio`,
      {
        //TESTIN
        // await fetch(`http://localhost:3000/studio/details/?type=L`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (!data.message) {
          setStudiosList(data);
          if (process.env.NODE_ENV === "development") {
            console.log("studiosData ----->", data);
          }
          setLoading(false);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  //Fetching studio details
  const fetchStudioData = async (id) => {
    //Production
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/${id}`,
      {
        //Testing
        // await fetch(`http://localhost:3000/studio/details/?type=D&id=${studioId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.message);
          }
          setLoading(false);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("STUDIO DATA=========>", data);
          }
          setStudioData(data.studio);
          setEquipmentData(data.studioEquipment);
          setStudioImages(data.studioImages);
          setReviews(data.reviews);
          setAvgRatings(data.rating);
          setCoupons(data.coupons);
          setPackages(data.packages);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  const fetchBookedSlots = async () => {
    //Production
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${
        process.env.REACT_APP_DOMAIN
      }/studio/${id}/slots?date=${moment(selectedDate).format("YYYY-MM-DD")}`,
      {
        //Testing
        // await fetch(`http://localhost:3000/studio/details/?type=D&id=${studioId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("Booked slots: ", data);
            console.log(
              "slot time",
              data[0]?.bookingDate === moment(selectedDate).format("YYYY-MM-DD")
                ? parseInt(data[0]?.fromHour.substring(0, 2))
                : "No slots"
            );
          }
          setSelectedSlots([]);
          setBookedSlots(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Something went wrong", data);
          }
          setBookedSlots([]);
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
        setBookedSlots([]);
      });
  };

  const fetchOperationalHrs = async (id) => {
    //Production
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/${id}/operationhours`,
      {
        //Testing
        // await fetch(`http://localhost:3000/studio/details/?type=D&id=${studioId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("Operational hrs: ", data);
          }
          Object.entries(data).map(([key, value]) => {
            return key === "createdAt" || key === "studioid"
              ? null
              : setOperationalHrs((prev) => ({
                  ...prev,
                  [key]: value,
                }));
          });
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Something went wrong", data);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  const fetchHostRules = async (id) => {
    //Production
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/${id}/hostrules`,
      {
        //Testing
        // await fetch(`http://localhost:3000/studio/details/?type=D&id=${studioId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("Host Rules: ", data);
          }
          setHostRules(data);
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Something went wrong", data);
          }
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      });
  };

  // Equipments Container
  const EquipmentsComponent = (eqData) => {
    //Iterating equipement data object and rendering equipments
    return (
      <div className="equipments-container">
        {eqData.eqData &&
          Object.entries(eqData.eqData)
            .filter(([key]) => {
              return key !== "sid";
            })
            .map(([key, value]) => {
              return (
                <div id="equipment-items" key={key}>
                  <p className="eq-name">•{key}:</p>
                  <p className="eq-value">
                    {value !== "" ? value.toString() : "No details found"}
                  </p>
                </div>
              );
            })}
        {!eqData.eqData ? (
          <div className="equipment-items">
            <p className="eq-value">No Equipment details found</p>
          </div>
        ) : null}
      </div>
    );
  };

  // Handle slots click
  const handleSlotClick = (slot) => {
    if (selectedSlots.includes(slot)) {
      setSelectedSlots(selectedSlots.filter((item) => item !== slot));
    } else {
      setSelectedSlots([...selectedSlots, slot]);
    }
  };

  const getStartEndTime = () => {
    if (!selectedSlots.length) {
      return;
    } else {
      selectedSlots.sort((a, b) => a - b);
      setStartTime(selectedSlots[0]);
      setEndTime(selectedSlots[selectedSlots.length - 1] + 1);
    }
  };

  const checkInvalidSlots = () => {
    if (
      new Date().toISOString().substring(0, 10).split("-").join("") <=
      new Date(selectedDate).toISOString().substring(0, 10).split("-").join("")
    ) {
      if (process.env.NODE_ENV === "development") {
        console.log(
          "future date true date",
          parseInt(moment(selectedDate).format("D"))
        );
      }
      return true;
    } else if (
      new Date().toISOString().substring(0, 10).split("-").join("") ===
        new Date(selectedDate)
          .toISOString()
          .substring(0, 10)
          .split("-")
          .join("") &&
      parseInt(moment().format("HH:mm").substring(0, 2)) < startTime
    ) {
      if (process.env.NODE_ENV === "development") {
        console.log(
          "future date true hrs",
          parseInt(moment(selectedDate).format("D"))
        );
      }
      return true;
    } else {
      if (process.env.NODE_ENV === "development") {
        console.log(
          "future date false",
          parseInt(moment(selectedDate).format("D"))
        );
      }
      return false;
    }
  };

  const checkConsecutiveSlots = (arr, n) => {
    // Sort the array
    arr.sort((a, b) => a - b);
    if (process.env.NODE_ENV === "development") {
      console.log("sorted arr", arr);
    }
    // checking the adjacent elements
    for (var i = 1; i < n; i++) if (arr[i] !== arr[i - 1] + 1) return false;

    return true;
  };

  const cancelPreview = () => {
    window.localStorage.removeItem("previewDetails");
    setLSPreviewDetails(null);
  };

  const TimerRenderer = ({ minutes, seconds }) => (
    <span>
      {minutes}:{seconds}
    </span>
  );

  //---CHECK ALL CONDITIONS BEFORE PROCEEDING---
  const proceedBooking = () => {
    if (
      window.localStorage.getItem("userId") !== null &&
      window.localStorage.getItem("userId") !== undefined &&
      window.localStorage.getItem("userId") !== "" &&
      window.localStorage.getItem("userId") !== "undefined"
    ) {
      if (
        selectedCheckbox.studio === false &&
        selectedCheckbox.jampad === false
      ) {
        notifyCheckboxErr(
          "Please select atleast one checkbox for Studio or Jampad"
        );
      } else {
        if (selectedSlots.length > 0) {
          if (!packageClicked) {
            if (
              checkConsecutiveSlots(selectedSlots, selectedSlots.length) ===
              true
            ) {
              if (checkInvalidSlots() === false) {
                if (process.env.NODE_ENV === "development") {
                  console.log("Inside inv");
                }
                notifySlotsErr(
                  "This time has already passed! Please select valid time slots"
                ); //Notify user that the time slot is invalid
              } else {
                const bookingDetailsLS = {
                  bookingDate: moment(selectedDate).format("YYYY-MM-DD"),
                  selectedSlots: selectedSlots,
                  totalPrice:
                    selectedSlots.length *
                    (selectedCheckbox.studio
                      ? studioData?.studioprice
                      : selectedCheckbox.jampad
                      ? studioData?.jampadprice
                      : 0),
                  pricePerHour: selectedCheckbox.studio
                    ? studioData?.studioprice
                    : selectedCheckbox.jampad
                    ? studioData?.jampadprice
                    : 0,
                  startTime: startTime,
                  endTime: endTime,
                  studioName: studioData?.name,
                  studioAddress:
                    studioData?.locality + " , " + studioData?.city,
                  jampadApplicable: studioData?.jampadApplicable,
                  option: selectedCheckbox.studio ? "studio" : "jampad",
                };
                window.localStorage.setItem(
                  "details",
                  JSON.stringify(bookingDetailsLS)
                );
                // blockSlots();
                navigate(`/payment/${id}`);
              }
            } else {
              notifySlotsErr("Please select consecutive slots!");
            }
          }
          if (packageSelected !== "") {
            if (process.env.NODE_ENV === "development") {
              console.log(
                "BOOKING DETAILS: ",
                studioData?.studioprice,
                studioData?.name
              );
            }
            const bookingDetailsLS = {
              bookingDate: moment(selectedDate).format("YYYY-MM-DD"),
              selectedSlots: selectedSlots,
              totalPrice: packageCost,
              pricePerHour: selectedCheckbox.studio
                ? studioData?.studioprice
                : selectedCheckbox.jampad
                ? studioData?.jampadprice
                : 0,
              startTime: startTime,
              endTime: endTime,
              studioName: studioData?.name,
              studioAddress: studioData?.locality + " , " + studioData?.city,
              jampadApplicable: studioData?.jampadApplicable,
              option: selectedCheckbox.studio ? "studio" : "jampad",

              selectedCoupon: selectedCoupon ? selectedCoupon : "",
              couponCost: couponCost,
              isFinalAmount: isFinalAmount,
              selectedPackage: packageSelected,
            };
            window.localStorage.setItem(
              "details",
              JSON.stringify(bookingDetailsLS)
            );
          } else {
            if (process.env.NODE_ENV === "development") {
              console.log(
                "BOOKING DETAILS: ",
                studioData?.studioprice,
                studioData?.name
              );
            }
            const bookingDetailsLS = {
              bookingDate: moment(selectedDate).format("YYYY-MM-DD"),
              selectedSlots: selectedSlots,
              totalPrice:
                selectedSlots.length *
                (selectedCheckbox.studio
                  ? studioData?.studioprice
                  : selectedCheckbox.jampad
                  ? studioData?.jampadprice
                  : 0),
              pricePerHour: selectedCheckbox.studio
                ? studioData?.studioprice
                : selectedCheckbox.jampad
                ? studioData?.jampadprice
                : 0,
              startTime: startTime,
              endTime: endTime,
              studioName: studioData?.name,
              studioAddress: studioData?.locality + " , " + studioData?.city,
              jampadApplicable: studioData?.jampadApplicable,
              option: selectedCheckbox.studio ? "studio" : "jampad",

              selectedCoupon: selectedCoupon ? selectedCoupon : "",
              couponCost: couponCost,
              isFinalAmount: isFinalAmount,
            };
            window.localStorage.setItem(
              "details",
              JSON.stringify(bookingDetailsLS)
            );
          }
          if (packageSelected !== "") {
            if (packageHours > 0 && selectedSlots.length === packageHours) {
              if (
                checkConsecutiveSlots(selectedSlots, selectedSlots.length) ===
                true
              ) {
                if (checkInvalidSlots() === false) {
                  if (process.env.NODE_ENV === "development") {
                    console.log("Inside inv");
                  }
                  notifySlotsErr(
                    "This time has already passed! Please select valid time slots"
                  ); //Notify user that the time slot is invalid
                } else {
                  // blockSlots();
                  navigate(`/payment/${id}`);
                }
              } else {
                notifySlotsErr("Please select consecutive slots!");
              }
            } else if (packageHours === 0) {
              if (
                checkConsecutiveSlots(selectedSlots, selectedSlots.length) ===
                true
              ) {
                if (checkInvalidSlots() === false) {
                  if (process.env.NODE_ENV === "development") {
                    console.log("Inside inv");
                  }
                  notifySlotsErr(
                    "This time has already passed! Please select valid time slots"
                  ); //Notify user that the time slot is invalid
                } else {
                  // blockSlots();
                  navigate(`/payment/${id}`);
                }
              } else {
                notifySlotsErr("Please select consecutive slots!");
              }
            } else {
              notifySlotsErr(
                "Please select same number of hours as mention in the package!"
              );
            }
          }
        } else {
          notifySlotsErr("Please select slots for booking!");
        }
      }
    } else {
      notifyErr("Please login to continue booking!");
    }
  };

  const Coupon = () => (
    <button
      onClick={() => {
        setCouponClicked(!couponClicked);
        setSelectedCoupon(coupons?.code);
        setCouponCost(coupons?.amount);
        setIsFinalAmount(coupons?.isFinalAmount);
      }}
      className={`studio-details-coupons-container${
        couponClicked ? "-clicked" : ""
      }`}
    >
      <div className="studio-details-coupons-left-container">
        <img
          src="https://img.icons8.com/office/100/null/discount.png"
          alt="offer"
          id="studio-offer-icon"
        />
      </div>
      <div className="studio-details-coupons-right-container">
        <p id="studio-coupon-text">20% discount on your first booking</p>
        <h1 id="studio-coupon-code">Use Code: {coupons.code}</h1>
      </div>
    </button>
  );

  return (
    <div>
      {loading ? (
        <div className="loader">
          <ScaleLoader
            color={color}
            loading={loading}
            height={100}
            width={20}
            radius={100}
            margin={10}
          />
        </div>
      ) : (
        <div className="studio-details-main-container">
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          {/* SHOW THIS MODAL ON PHONE SCREENS */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            // hideBackdrop
          >
            <Box id="studio-modal">
              <Typography id="studio-modal-title" variant="h6" component="h2">
                Select Date & Time
                <Close onClick={handleClose} id="close-btn" />
              </Typography>
              <div className="studio-modal-content">
                <BookingComponent
                  screenWidthChanged={screenWidth > 600 ? false : true}
                  slots={slots}
                  onSlotClick={handleSlotClick}
                  selectedSlots={selectedSlots}
                  bookedSlots={bookedSlotsArray}
                  selectedDate={selectedDate}
                  selectedCheckbox={selectedCheckbox}
                  studioData={studioData}
                  setSelectedDate={setSelectedDate}
                  endTime={endTime}
                  startTime={startTime}
                  packages={packages}
                  setPackageClicked={setPackageClicked}
                  packageClicked={packageClicked}
                  setPackageSelected={setPackageSelected}
                  packageSelected={packageSelected}
                  proceedBooking={proceedBooking}
                  setPackageCost={setPackageCost}
                  setPackageHours={setPackageHours}
                  setSelectedCheckbox={setSelectedCheckbox}
                  liveBookingArray={liveBookingArray}
                />
              </div>
            </Box>
          </Modal>
          <Helmet>
            <title>Jamrio | {studioData?.name || "Studio"}</title>
            <meta
              name="description"
              content="Best place for booking and finding music studios, jampads,audio engineers, shoot locations and much more, Read blogs, Read Artist Testimonies and much more!"
            />
            <meta
              name="keywords"
              content="Music, Music Studios, Jampads, Audio Engineers, Book Studio, Shoot Locations"
            />
          </Helmet>
          <div className="studio-details-container">
            <div className="studio-middle-container">
              <div className="studio-middle-left-container">
                <div className="studio-images-main-container">
                  <div className="studio-images-left-container">
                    <img
                      className="studio-details-images"
                      src={
                        studioImages?.length > 0
                          ? `${studioImages[0]?.url} `
                          : placeholderImage
                      }
                      alt="music studio"
                    />
                  </div>
                </div>
                <div className="studio-basic-details-container">
                  <div className="studio-name-ratings-container">
                    <h1 id="studio-details-name">{studioData?.name}</h1>
                    {/* <p id="studio-details-rating">
                      ⭐{avgRatings ? avgRatings : 0}
                    </p>
                  </div> */}
                    {screenWidth > 900 && (
                      <div className="studio-cost-ratings-container">
                        <h1 id="studio-details-cost">
                          ₹
                          {selectedCheckbox.jampad
                            ? selectedSlots?.length === 0
                              ? studioData?.jampadprice
                              : selectedSlots?.length * studioData?.jampadprice
                            : selectedSlots?.length === 0
                            ? studioData?.studioprice
                            : selectedSlots?.length * studioData?.studioprice}
                        </h1>{" "}
                        <p id="studio-details-rating">
                          ⭐{avgRatings ? avgRatings : 0}
                        </p>
                      </div>
                    )}
                  </div>
                  <p id="studio-details-address">
                    {studioData?.address},{studioData?.city},{" "}
                    {studioData?.state}
                  </p>
                  {screenWidth < 900 && (
                    <div className="studio-cost-ratings-container">
                      <h1 id="studio-details-cost">
                        ₹
                        {selectedCheckbox.jampad
                          ? selectedSlots?.length === 0
                            ? studioData?.jampadprice
                            : selectedSlots?.length * studioData?.jampadprice
                          : selectedSlots?.length === 0
                          ? studioData?.studioprice
                          : selectedSlots?.length * studioData?.studioprice}
                      </h1>{" "}
                      <p id="studio-details-rating">
                        ⭐{avgRatings ? avgRatings : 0}
                      </p>
                    </div>
                  )}
                  {coupons &&
                    coupons?.validityEnd > new Date().toISOString() && (
                      <Coupon />
                    )}
                </div>
                {/* ---------About------------ */}
                {studioData?.about && (
                  <div className="studio-details-about-container">
                    <h1 id="studio-details-title">About Location</h1>
                    <p>
                      Impression Studio Production in Vidhyavihar, Mumbai is a
                      reliable name in the industry as they aim to deliver the
                      best experience to their customers. This has helped them
                      build up a loyal customer base. They started their journey
                      in 2021 and ever since, they have ensured that the
                      customer remains at the centre of their business
                      operations and philosophy.
                    </p>
                  </div>
                )}
                <hr className="studio-hr" />
                {/* --------------Services------------- */}
                <div className="studio-details-services-container">
                  <h1 id="studio-details-title">Services</h1>
                  <div className="studio-details-services-inner-container">
                    <div className="studio-service-container">
                      <img
                        className="studio-service-img"
                        src="https://images.unsplash.com/photo-1581548708095-7158f2e63857?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                        alt="service"
                      />
                      <p>Recording</p>
                    </div>
                    <div className="studio-service-container">
                      <img
                        className="studio-service-img"
                        src="https://images.unsplash.com/photo-1630965764686-159c575031b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                        alt="service"
                      />
                      <p>Mixing & Mastering</p>
                    </div>
                    <div className="studio-service-container">
                      <img
                        className="studio-service-img"
                        src="https://images.unsplash.com/photo-1601814837661-ce2832acd413?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                        alt="service"
                      />
                      <p>Dubbing</p>
                    </div>
                  </div>
                </div>
                <hr className="studio-hr" />
                {/* -------Equipments----------- */}
                {/* <div className="studio-details-features-container">
                  <h1 id="studio-details-title">Equipments</h1>
                  <div className="studio-details-feature-inner-container">
                    <EquipmentsComponent eqData={equipmentData} />
                  </div>
                </div> */}
                <div className="studio-details-equipments-container">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<Add />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography id="studio-details-accordion-title">
                        Equipments
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="studio-details-feature-inner-container">
                        <EquipmentsComponent eqData={equipmentData} />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                {/* <hr className="studio-hr" /> */}
                {/* --------------Operational Hour--------- */}
                <div className="studio-details-equipments-container">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<Add />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography id="studio-details-accordion-title">
                        Operational Hours
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="studio-details-host-rules">
                        {Object.entries(operationalHrs)
                          ?.slice(0, weekendOff ? 5 : 7)
                          ?.map(function (item) {
                            return (
                              <ul className="studio-details-operational-hrs-list">
                                <li id="studio-operational-hrs-day">
                                  <p id="studio-operational-hrs-day">
                                    {item[0]}:
                                  </p>
                                </li>
                                <li id="studio-operational-hrs-text">
                                  <div id="studio-operational-hrs-time-container">
                                    <p>{item[1].from}</p> - <p>{item[1].to}</p>
                                  </div>
                                </li>
                              </ul>
                            );
                          })}
                        {weekendOff && (
                          <p id="studio-operational-hrs-dayoff">
                            Closed on Weekend
                          </p>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                {/* -----------Host Rules----------- */}
                <div className="studio-details-equipments-container">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<Add />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography id="studio-details-accordion-title">
                        Host Rules
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="studio-details-host-rules">
                        <ol className="studio-details-rules-list">
                          {hostRules?.length > 0 ? (
                            hostRules?.map((rule) => {
                              return (
                                <li>
                                  <p className="host-rule">{rule.title}</p>
                                </li>
                              );
                            })
                          ) : (
                            <p>No Rules Added</p>
                          )}
                        </ol>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                {/* <hr className="studio-hr" /> */}
                {/* -----------Location Map---------  */}
                {/* <div className="studio-details-map-main-container">
                <h1 id="studio-details-title">Location</h1>
                <div></div>
              </div> */}
                {/* <div class="mapouter">
                  <div class="gmap_canvas">
                    <iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=17, Basavanna Street, Adugodi, Bengaluru 560030&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                    <a href="https://formatjson.org/word-counter">Word Counter</a>
                  </div>
              </div> */}
              </div>
              {/* -----------FOR NORMAL SCREENS------------ */}
              {screenWidth > 900 && (
                <div className="studio-middle-right-container">
                  <div className="studio-booking-container">
                    <div className="studio-details-date-time-container">
                      <h1 id="studio-details-title-booking">
                        Select Date & Time
                      </h1>
                      {/* <div className="studio-details-date-container"> */}
                      <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        className="studio-booking-date-calendar"
                        minDate={moment().toDate()}
                        dateFormat="dd MMMM yyyy"
                        required={true}
                      />
                      <div className="studio-details-time-container">
                        <div className="studio-details-start-time-container">
                          <p className="studio-details-slot-text">
                            {startTime
                              ? parseInt(startTime) > 12
                                ? parseInt(startTime) - 12 + "pm"
                                : parseInt(startTime) === 12
                                ? parseInt(startTime) + "pm"
                                : parseInt(startTime) + "am"
                              : "00:00"}
                          </p>
                        </div>
                        <div className="studio-details-end-time-container">
                          <p className="studio-details-slot-text">
                            {endTime
                              ? parseInt(endTime) > 12
                                ? endTime - 12 + "pm"
                                : parseInt(endTime) === 12
                                ? parseInt(endTime) + "pm"
                                : parseInt(endTime) + "am"
                              : "00:00"}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="studio-booking-slots-container"></div> */}
                    <SlotsComponent
                      screenWidthChanged={screenWidth > 600 ? false : true}
                      slots={slots}
                      onSlotClick={handleSlotClick}
                      selectedSlots={selectedSlots}
                      bookedSlots={bookedSlotsArray}
                      selectedDate={selectedDate}
                      liveBookingArray={liveBookingArray}
                    />
                    <div className="studio-details-attendees-main-container">
                      {studioData?.studioprice > 0 ? (
                        <div
                          className={
                            selectedCheckbox.studio
                              ? "option-selection-inner-selected"
                              : "option-selection-inner-container"
                          }
                        >
                          <div className="option-details-container">
                            <Checkbox
                              checked={selectedCheckbox.studio}
                              className="checkbox"
                              sx={{
                                color: orange[600],
                                "&.Mui-checked": {
                                  color: orange[600],
                                },
                                "& .MuiSvgIcon-root": { fontSize: 26 },
                              }}
                              onChange={(e) => {
                                setSelectedCheckbox({
                                  studio: e.target.checked,
                                  jampad: false,
                                });
                              }}
                            />
                            <h3 className="option-name">
                              Book Studio ₹{studioData?.studioprice}/hr
                            </h3>
                          </div>
                        </div>
                      ) : null}
                      {studioData?.jampadprice > 0 ? (
                        <div
                          className={
                            selectedCheckbox.jampad
                              ? "option-selection-inner-selected"
                              : "option-selection-inner-container"
                          }
                        >
                          <div className="option-details-container">
                            <Checkbox
                              checked={selectedCheckbox.jampad}
                              className="checkbox"
                              sx={{
                                color: orange[600],
                                "&.Mui-checked": {
                                  color: orange[600],
                                },
                                "& .MuiSvgIcon-root": { fontSize: 26 },
                              }}
                              onChange={(e) => {
                                setSelectedCheckbox({
                                  jampad: e.target.checked,
                                  studio: false,
                                });
                              }}
                            />
                            <h3 className="option-name">
                              Book Jampad ₹{studioData?.jampadprice}/hr
                            </h3>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {packages?.length > 0 && (
                      <div className="studio-details-attendees-main-container">
                        <Accordion id="accordion">
                          <AccordionSummary
                            expandIcon={<Add />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <p id="studio-details-title-package">
                              Additional Packages
                            </p>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="studio-details-packages-container">
                              {packages?.length > 0
                                ? packages?.map((pkg) => (
                                    <div
                                      onClick={() => {
                                        setPackageClicked(!packageClicked);
                                        setPackageSelected(pkg?.id);
                                        setPackageCost(pkg?.cost);
                                        setPackageHours(pkg?.hours_allowed);
                                      }}
                                      className={`location-package${
                                        packageSelected === pkg.id
                                          ? "-selected"
                                          : ""
                                      }`}
                                    >
                                      <p id="studio-package-name">
                                        {pkg?.name}
                                      </p>
                                      <p id="studio-package-desc">
                                        {pkg?.description}
                                      </p>
                                      <p id="studio-package-cost">
                                        ₹{pkg?.cost} for {pkg.hours_allowed}hrs
                                      </p>
                                    </div>
                                  ))
                                : null}
                            </div>
                            {packageSelected !== "" && (
                              <button
                                id="cancel-package"
                                onClick={() => {
                                  setPackageSelected("");
                                  setPackageClicked(false);
                                }}
                              >
                                Cancel Package
                              </button>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}
                    <button
                      onClick={() => {
                        proceedBooking();
                      }}
                      id="studio-details-booking-btn"
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* ---------Explore mpre studios and reviews */}
          <div className="studio-details-bottom-container">
            <hr className="studio-hr" />
            <div className="studio-details-explore-container">
              <p id="studio-details-title">Explore More Studios</p>
              <div className="studios-wrapper">
                <Carousel
                  containerClass="studio-cards-main-container"
                  responsive={studiosResponsive}
                >
                  {studiosList?.length > 0 &&
                    studiosList.map((studio, index) => (
                      <div
                        onClick={() => {
                          window.scrollTo(0, 0);
                          fetchStudioData(studio.id);
                        }}
                        className="explore-studios-container"
                      >
                        <StudioContainerListing
                          id={studio.id}
                          name={studio.name}
                          address={studio.locality + ", " + studio.city}
                          price={studio.studioprice}
                          image={studio.images[0]?.url}
                          key={index}
                        />
                      </div>
                    ))}
                </Carousel>
              </div>
            </div>
            <hr className="studio-hr" />
            <div className="studio-details-reviews-main-container">
              <h1 id="studio-details-title">Reviews</h1>
              {/* <div className="studio-details-reviews-inner-container"> */}
              <div>
                {/* {reviews?.length > 0 ? (
                  reviews?.map((review) => (
                    <div className="studio-details-review">
                      <div className="studio-details-review-top">
                        <img
                          src="https://img.icons8.com/material-rounded/100/000000/user-male-circle.png"
                          alt="reviewer"
                          id="studio-details-reviewer-img"
                        />
                        <div className="studio-details-review-name-container">
                          <p id="studio-details-reviewer-name">
                            {review?.name}
                          </p>
                          <p id="studio-details-reviewer-stars">
                            {"⭐".repeat(review?.score)}
                          </p>
                        </div>
                      </div>
                      <p>{review?.text}</p>
                    </div>
                  ))
                ) : (
                  <p>No Reviews Yet</p>
                )} */}
                <Reviews reviews={reviews} />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* FLOATING BOOKING PREVIEW AND BUTTON FOR SMALLER SCREENS */}
      {screenWidth < 900 && (
        <div className="studio-details-booking-btn-and-preview">
          {window.localStorage.getItem("previewDetails") && !open && (
            <div className="studio-details-booking-preview">
              {/* {console.log(
                "preview local storage:",
                JSON.parse(window.localStorage.getItem("previewDetails"))
              )} */}
              <div className="booking-preview-left">
                <p id="booking-preview-studio-name">{studioData?.name}</p>
                <p>{moment(LSPreviewDetails?.date).format("DD/MM/YYYY")}</p>
                <p>
                  {LSPreviewDetails?.start
                    ? parseInt(LSPreviewDetails?.start) > 12
                      ? parseInt(LSPreviewDetails?.start) - 12 + "pm"
                      : parseInt(LSPreviewDetails?.start) === 12
                      ? parseInt(LSPreviewDetails?.start) + "pm"
                      : parseInt(LSPreviewDetails?.start) + "am"
                    : "00:00"}{" "}
                  -{" "}
                  {LSPreviewDetails?.end
                    ? parseInt(LSPreviewDetails?.end) > 12
                      ? LSPreviewDetails?.end - 12 + "pm"
                      : parseInt(LSPreviewDetails?.end) === 12
                      ? parseInt(LSPreviewDetails?.end) + "pm"
                      : parseInt(LSPreviewDetails?.end) + "am"
                    : "00:00"}
                </p>
              </div>
              <div className="booking-preview-right">
                <p id="booking-preview-cancel" onClick={cancelPreview}>
                  Cancel
                </p>
                <p id="booking-preview-timer">
                  {
                    <Countdown
                      daysInHours
                      date={Date.now() + 300000}
                      renderer={TimerRenderer}
                      autoStart={window.localStorage.getItem("previewDetails")}
                      onComplete={cancelPreview}
                    />
                  }
                </p>
                <p id="booking-preview-cost">₹{previewDetails.cost}/hr</p>
              </div>
            </div>
          )}
          <div className="book-now-floating-btn-container">
            <button
              onClick={handleOpen}
              id={`floating-booking-btn${!floatingBtn ? "-disabled" : ""}`}
            >
              Book Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudioDetailsNew;
