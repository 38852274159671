import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import NavigationContext from "../../Context/NavigationContext";
import SortingContext from "../../Context/SortingContext";

import "./styles.css";
import { useNavigate } from "react-router-dom";
import {
  AccountBalanceWalletOutlined,
  EventAvailableOutlined,
  HelpOutlineOutlined,
  InboxOutlined,
  PeopleAltOutlined,
  PermContactCalendarOutlined,
  QuestionMark,
  SettingsOutlined,
} from "@mui/icons-material";

export default function SideNavBar() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // Navbar states
  const [userData, setUserData] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const { setOpenComponent } = useContext(NavigationContext);
  const { sortCity, setSortCity } = useContext(SortingContext);
  const [cities, setCities] = useState([]);
  const [LSCities, setLSCities] = useState([]);
  const [anchorEl, setAnchorEl] = useState(false);
  const ITEM_HEIGHT = 42;
  const openCities = Boolean(anchorEl);
  const userExists = window.localStorage.getItem("userId");
  const handleClickCities = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    fetchUserData();
  }, [userExists]);

  useEffect(() => {
    setLSCities(window.localStorage.getItem("cities"));
  }, []);
  useEffect(() => {
    if (!window.localStorage.getItem("cities")) {
      GetCities();
    } else {
      setCities(JSON.parse(window.localStorage.getItem("cities")));
    }
  }, [LSCities]);

  const fetchUserData = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/user/${userExists}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (process.env.NODE_ENV === "development") {
          console.log("Response Body -> ", JSON.parse(JSON.stringify(data)));
        }

        if (!data.isError) {
          setUserData(data);
          if (process.env.NODE_ENV === "development") {
            //
            console.log("USER DETAILS ----->", data);
          }
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.isError);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetCities = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/cities/unique`,
      {
        //Testing
        // await fetch(`http://localhost:3000/studio/details/?type=D&id=${studioId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("CITY LIST ->", data);
          }
          window.localStorage.setItem("cities", JSON.stringify(data));
        } else {
          console.log("Something went wrong", data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onClickLogin = () => {
    navigate("/login/phone");
  };

  const ProfileIcon = () => (
    <button
      onClick={() => {
        //setToggleMenu(false);
        setOpenComponent("Profile");
        navigate("/dashboard");
      }}
      className="sidebar-profile-icon-container"
    >
      <img
        alt={userData?.name}
        // id={userExists ? "profileIcon" : "profileIcon-disabled"}
        id="profileIcon"
        src={
          userData?.image
            ? userData?.image
            : "https://img.icons8.com/material-rounded/100/000000/user-male-circle.png"
        }
      />
      <p>
        {userData?.name?.length > 10
          ? userData?.name?.substring(0, 10) + "..."
          : userData?.name}
      </p>
    </button>
  );

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <div className="NavbarJamrio-items-sidebar">
        <div className="NavbarJamrio-text-items-sidebar">
          {(
            window.localStorage.getItem("userId")
              ? window.localStorage.getItem("userId")
              : false
          ) ? (
            <>
              <div className="sidebar-item">
                <ProfileIcon />
              </div>
              <Divider className="divider" />
              <div
                className="sidebar-item"
                onClick={() => {
                  //setToggleMenu(false);
                  setOpenComponent("Bookings");
                  navigate("/dashboard");
                }}
              >
                <div className="icon-background">
                  <EventAvailableOutlined />
                </div>
                <p className="NavbarJamrio-text"> Bookings </p>
              </div>
              <Divider className="divider" />
              <div
                className="sidebar-item"
                onClick={() => {
                  //setToggleMenu(false);
                  navigate("/wallet");
                }}
              >
                <div className="icon-background">
                  <AccountBalanceWalletOutlined />
                </div>
                <p className="NavbarJamrio-text"> Wallet </p>
              </div>
              <Divider className="divider" />
              <div
                className="sidebar-item"
                onClick={() => {
                  //setToggleMenu(false);
                  navigate("/referral");
                }}
              >
                <div className="icon-background">
                  <PeopleAltOutlined />
                </div>
                <p className="NavbarJamrio-text"> Referral </p>
              </div>
              <Divider className="divider" />

              <Divider className="divider" />
              <div
                className="sidebar-item"
                onClick={() => {
                  //setToggleMenu(false);
                  navigate("/contact-us");
                }}
              >
                <div className="icon-background">
                  <PermContactCalendarOutlined />
                </div>
                <p className="NavbarJamrio-text"> Contact Us </p>
              </div>
              <Divider className="divider" />
              <div
                className="sidebar-item"
                onClick={() => {
                  //setToggleMenu(false);
                  navigate("/inbox/null");
                }}
              >
                <div className="icon-background">
                  <InboxOutlined />
                </div>
                <p className="NavbarJamrio-text"> Inbox </p>
              </div>
              <Divider className="divider" />
              <div
                className="sidebar-item"
                onClick={() => {
                  //setToggleMenu(false);
                  navigate("/coming-soon");
                }}
              >
                <div className="icon-background">
                  <SettingsOutlined />
                </div>
                <p className="NavbarJamrio-text"> Settings </p>
              </div>
              <Divider className="divider" />
              <div
                className="sidebar-item"
                onClick={() => {
                  //setToggleMenu(false);
                  setOpenComponent("Bookings");
                  navigate("/faqs");
                }}
              >
                <div className="icon-background">
                  <QuestionMark />
                </div>
                <p className="NavbarJamrio-text"> FAQs </p>
              </div>
              <Divider className="divider" />
            </>
          ) : (
            // </div>
            <>
              <div
                className="faq-btn-text"
                onClick={() => {
                  //setToggleMenu(false);
                  setOpenComponent("Bookings");
                  navigate("/faqs");
                }}
              >
                <div className="icon-background">
                  <QuestionMark />
                </div>
                <p className="NavbarJamrio-text"> FAQs </p>
              </div>
              <Divider className="divider" />
              <div
                onClick={() => {
                  onClickLogin();
                  //setToggleMenu(false);
                }}
                className="login-btn"
              >
                <p className="login-btn-text"> Login / Register </p>
              </div>
            </>
          )}
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button id="toggle" onClick={toggleDrawer(anchor, true)}>
            <img
              src="https://img.icons8.com/ios-glyphs/100/000000/menu--v1.png"
              alt="menu"
              className="menu-sidebar"
            />
          </Button>
          <Drawer
            id="drawer"
            className="drawer"
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
