import React, { useEffect, useState, useContext } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { MenuItem, Menu } from "@mui/material/";
import NavigationContext from "../../Context/NavigationContext";
import SortingContext from "../../Context/SortingContext";
import logo from "../../Images/jamrioLogo.webp";
import { Inbox, Notifications } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SideNavBar from "../../components/SideNavBar/SideNavBar";

function NavbarJamrio() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [userData, setUserData] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const { setOpenComponent } = useContext(NavigationContext);
  const { sortCity, setSortCity } = useContext(SortingContext);
  const [cities, setCities] = useState([]);
  const [LSCities, setLSCities] = useState([]);
  const [anchorEl, setAnchorEl] = useState(false);
  const ITEM_HEIGHT = 42;
  const openCities = Boolean(anchorEl);
  const userExists = window.localStorage.getItem("userId");
  const handleClickCities = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };

  const menuClicked = () => {
    setToggleMenu(!toggleMenu);
  };

  // const ClickToggleDrawer = (open) => (event) => {
  //   if (
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   setToggleDrawer(open);
  // };

  const ProfileIcon = () => (
    <img
      alt={userData?.name}
      // id={userExists ? "profileIcon" : "profileIcon-disabled"}
      id="profileIcon"
      onClick={() => {
        setToggleMenu(false);
        setOpenComponent("Profile");
        navigate("/dashboard");
      }}
      src={
        userData?.image
          ? userData?.image
          : "https://img.icons8.com/material-rounded/100/000000/user-male-circle.png"
      }
    />
  );

  useEffect(() => {
    fetchUserData();
  }, [userExists]);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);
  }, []);

  useEffect(() => {
    setLSCities(window.localStorage.getItem("cities"));
  }, []);
  useEffect(() => {
    if (!window.localStorage.getItem("cities")) {
      GetCities();
    } else {
      setCities(JSON.parse(window.localStorage.getItem("cities")));
    }
  }, [LSCities]);

  const fetchUserData = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/user/${userExists}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${window.localStorage.getItem("AuthToken")}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (process.env.NODE_ENV === "development") {
          console.log("Response Body -> ", JSON.parse(JSON.stringify(data)));
        }

        if (!data.isError) {
          setUserData(data);
          if (process.env.NODE_ENV === "development") {
            //
            console.log("USER DETAILS ----->", data);
          }
        } else {
          if (process.env.NODE_ENV === "development") {
            console.log("Failed", data.isError);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetCities = async () => {
    await fetch(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_DOMAIN}/studio/cities/unique`,
      {
        //Testing
        // await fetch(`http://localhost:3000/studio/details/?type=D&id=${studioId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (process.env.NODE_ENV === "development") {
            console.log("CITY LIST ->", data);
          }
          window.localStorage.setItem("cities", JSON.stringify(data));
        } else {
          console.log("Something went wrong", data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onClickLogin = () => {
    navigate("/login/phone");
  };

  // const NavItemsList = () => (
  //   <Box
  //     sx={{ width: 250 }}
  //     role="presentation"
  //     onClick={ClickToggleDrawer(false)}
  //     onKeyDown={ClickToggleDrawer(false)}
  //   >
  //     <List>
  //       {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
  //         <ListItem key={text} disablePadding>
  //           <ListItemButton>
  //             <ListItemIcon>
  //               {index % 2 === 0 ? <Inbox /> : <Notifications />}
  //             </ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //     <Divider />
  //     <List>
  //       {["All mail", "Trash", "Spam"].map((text, index) => (
  //         <ListItem key={text} disablePadding>
  //           <ListItemButton>
  //             <ListItemIcon>
  //               {index % 2 === 0 ? <Inbox /> : <Notifications />}
  //             </ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //   </Box>
  // );

  return (
    <div id="NavbarJamrio">
      <SideNavBar />
      <img
        // src="https://i.ibb.co/WzwwLrk/jmar-logo-cropped.png"
        src={logo}
        alt="jamr-logo"
        id="jamr-logo"
        onClick={() => {
          navigate("/");
        }}
      />
      {/* {userExists && } */}
      {(toggleMenu || screenWidth > 1000) && (
        <div className="NavbarJamrio-items">
          <Box cursor="pointer" className="location">
            <img
              src="https://img.icons8.com/ios-filled/100/FF782C/marker.png"
              alt="location-marker"
              className="location-marker"
              onClick={() => {
                document.getElementById("handleDropdown").click();
              }}
            />
            <h2
              className="location-text"
              onClick={() => {
                document.getElementById("handleDropdown").click();
              }}
            >
              {" "}
              {sortCity}
            </h2>
            <img
              src="https://img.icons8.com/ios-glyphs/30/000000/sort-down.png"
              alt="dropdown-arrow"
              className="dropdown-arrow"
              id="handleDropdown"
              onClick={handleClickCities}
            />
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={openCities}
              onClose={handleClose}
              disableScrollLock={true}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  setSortCity("All");
                  handleClose();
                }}
              >
                All
              </MenuItem>
              {cities &&
                cities?.map((city) => (
                  <MenuItem
                    onClick={() => {
                      setSortCity(city);
                      handleClose();
                    }}
                    key={city}
                  >
                    {city}
                  </MenuItem>
                ))}
            </Menu>
          </Box>
          <div className="NavbarJamrio-text-items">
            {(
              window.localStorage.getItem("userId")
                ? window.localStorage.getItem("userId")
                : false
            ) ? (
              <>
                <p
                  onClick={() => {
                    setToggleMenu(false);
                    setOpenComponent("Bookings");
                    navigate("/dashboard");
                  }}
                  className="NavbarJamrio-text"
                >
                  {" "}
                  Bookings{" "}
                </p>
                <p
                  onClick={() => {
                    setToggleMenu(false);
                    navigate("/referral");
                  }}
                  className="NavbarJamrio-text"
                >
                  {" "}
                  Referral{" "}
                </p>
                <p
                  onClick={() => {
                    setToggleMenu(false);
                    navigate("/wallet");
                  }}
                  className="NavbarJamrio-text"
                >
                  {" "}
                  Wallet{" "}
                </p>
                <p
                  onClick={() => {
                    setToggleMenu(false);
                    navigate("/faqs");
                  }}
                  className="NavbarJamrio-text"
                >
                  {" "}
                  FAQs{" "}
                </p>
                <Inbox
                  fontSize="large"
                  onClick={() => {
                    setToggleMenu(false);
                    navigate("/inbox/null");
                  }}
                />
                <ProfileIcon />
              </>
            ) : (
              // </div>
              <>
                <p
                  onClick={() => {
                    setToggleMenu(false);
                    navigate("/coming-soon");
                  }}
                  className="NavbarJamrio-text"
                >
                  {" "}
                  FAQs{" "}
                </p>
                <div
                  onClick={() => {
                    onClickLogin();
                    setToggleMenu(false);
                  }}
                  className="navbar-login-btn"
                >
                  <p className="navbar-login-btn-text"> Login / Register </p>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {/* {window.localStorage.getItem("userId") && ( */}
      <img
        src="https://img.icons8.com/ios-glyphs/100/000000/menu--v1.png"
        alt="menu"
        className="menu"
        onClick={menuClicked}
      />
      {/* <div>
        <React.Fragment>
          <Button onClick={ClickToggleDrawer(true)}>
            <img
              src="https://img.icons8.com/ios-glyphs/100/000000/menu--v1.png"
              alt="menu"
              className="menu"
            />
          </Button>
          <Drawer open={toggleDrawer} onClose={ClickToggleDrawer(false)}>
            {NavItemsList}
          </Drawer>
        </React.Fragment>
      </div> */}
    </div>
  );
}

export default NavbarJamrio;
